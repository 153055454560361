.pin-input {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .pin-input-box {
    align-items: center;
    background: var(--base-white, #fff);
    border: 1px solid var(--gray-300, #d0d5dd);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
    display: flex;
    flex-shrink: 0;
    font-family: PoppinsRegular, sans-serif;
    font-size: large;
    gap: 8px;
    height: 70px;
    outline: none;
    padding: 10px 14px;
    text-align: center;
    width: 64.75px;
  }

  .pin-input-box:focus::placeholder {
    color: transparent;
  }

  .pin-input-box::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
