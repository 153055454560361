
.push-notification {
  background: #ffff;
  border-radius: 10px;
  box-shadow: 3px 3px 13px -5px rgb(0 0 0 / 40%);
  height: fit-content;
  left: 50%;
  max-width: 400px;
  position: fixed;
  top: 103px;
  transform: translate(-50%, 0%);
  transition: all 0.3s ease 0s;
  width: 99%;
  z-index: 2000;

  .header-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: grid;
    font-size: 14px;
    grid-template-areas: 'header-icon header-text';
    grid-template-columns: 65px 1fr;
    height: fit-content;
    min-height: 50px;
    text-align: left;

    .header-icon {
      color: #fff;
      width: 100%;

      .inner-header-icon {
        margin: auto;
        position: relative;
        top: 50%;
        transform: translate(0%, -50%);
        width: fit-content;

        #icon {
          font-size: 28px;
        }
      }
    }

    .header-text {
      color: #fff;
      font-size: 20px;
      height: fit-content;
      margin-bottom: 0;
      padding-top: 10px;
    }

    .notification-close-btn {
      background: none;
      border: 0;
      border-radius: 100%;
      box-shadow: none;
      position: absolute;
      right: 3px;
      top: 3px;
      transform: rotate(45deg);

      .pi-plus {
        font-size: 18px;
      }
    }
  }
    
  .message-container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #000;
    height: fit-content;
    margin-bottom: 2px;
    padding-bottom: 1px;

    .message-text {
      font-size: 15px;
      padding: 12px 20px;
    }
  }
}

.push-notification-show {
  transform: translate(-50%, 0%);
}

.push-notification-hide {
  transform: translate(-50%, -250%);
}

@media screen and (width >= 400px) {
  .push-notification {
    left: initial;
    right: 50%;
    transform: translate(0%, 0%);
  }

  .push-notification-show {
    transform: translate(50%, 0%);
  }
    
  .push-notification-hide {
    transform: translate(50%, -250%);
  }
}
