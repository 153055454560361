@import 'node_modules/primeflex/primeflex.scss';
@import 'prismjs/themes/prism-coy.css';
@import '@fullcalendar/core/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
@import './Admin/Admin.scss';
// Client
@import './Client/ClientApp.scss';
@import './common/AdminMerchantResponsive.scss';
// @import './Client/pages/ClientLogin.scss';
// @import './Client/components/ClientTransactions.scss';
// @import './Client/pages/Merchants.scss';
// @import './Client/pages/ClientDashboard.scss';
// @import './Client/components/ClientBalance.scss';
// @import './Client/components/ClientFooter.scss';
// @import './Client/components/ClientLoggedInFooter.scss';

$gutter: 1rem;

body {
  background: #f2f2f2;
  overflow-x: hidden;
}

.font-face-poppins-b {
  font-family: PoppinsBold, sans-serif;
}

.font-face-poppins-sb {
  font-family: PoppinsSemiBold, sans-serif;
}

.font-face-poppins-r {
  font-family: PoppinsRegular, sans-serif;
}

.font-face-poppins-m {
  font-family: PoppinsMedium, sans-serif;
}

.font-face-vadodara-b {
  font-family: VadodaraBold, sans-serif;
}

.font-face-vadodara-sb {
  font-family: VadodaraSemiBold, sans-serif;
}

.font-face-vadodara-r {
  font-family: VadodaraRegular, sans-serif;
}

.font-face-vadodara-m {
  font-family: VadodaraMedium, sans-serif;
}

.text-center {
  text-align: center;
}

.spinner-small {
  height: 24px;
  margin: 8px;
  width: 24px;
}

.text-bold {
  font-weight: 600 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}
