.help {
  margin: auto;
  max-width: 845px;
  padding-top: 75px;

  &-answer-selected.card.answer-option {
    background: #3d5cff;
    color: white;
  }

  &-progress-spinner {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 200px;
  }

  &-back {
    position: absolute;
    top: 23px;
  }

  .question-btn {
    box-shadow: none;
  }
}
