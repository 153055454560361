.vula-card.standard {
  background: var(--base-white, #fff);
  border: 1px solid var(--gray-200, #eaecf0);
  border-radius: 18px;
  box-shadow: 0 4px 6px -2px rgb(16 24 40 / 3%), 0 12px 16px -4px rgb(16 24 40 / 8%);
  height: fit-content;
  padding: 26px 24px;
  width: 100%;
}

.vula-card.component-background {
  align-items: center;
  background: var(--gray-100, #f6f6f6);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
  width: 100%;
}
