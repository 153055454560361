.pin-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
