/* Utils */
.clearfix:after {
  content: ' ';
  display: block;
  clear: both;
}

.card {
  background: $cardBg;
  padding: 1.25rem;
  // padding-top: 1.25rem;
  // padding-bottom: 1.25rem;
  border: $cardBorder;
  margin-bottom: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .card-header {
    @include flex();
    @include flex-align-center();
    @include flex-justify-between();
  }

  .card-subtitle {
    color: $textSecondaryColor;
    font-weight: 600;
    margin: -1rem 0 1rem 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 70px;
  }
}
