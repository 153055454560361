//@import "./assets/flags/flags.css";
@import './Admin/assets/flags/flags.css';

.retailer-dialog { 
  &-color-picker-circle {
    border: 1.7px solid #ced4d9;
    border-radius: 100%;
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 20px;
  }

  .sketch-picker {
    position: absolute;
    top: 80%;
  }
}

.cell-number-dialog {
  .p-dialog-footer {
    .p-button {
      height: 35px;
    }
  }

  &.header-buttons {
    button,
    .p-fileupload-choose {
      background: none;
      border: 0;

      &:hover,
      &:active,
      &:focus {
        background: none;
        border: 0;
        box-shadow: none;
        color: initial;
      }
    }
  }
}

.topup-dialog {
  &-label {
    color: #000;
    font-family: PoppinsMedium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
    margin-bottom: 0.2rem;
    margin-left: 10px;
  }

  .p-inputgroup {
    .p-inputgroup-addon.vula {
      border-bottom-right-radius: 4px;
      border-right: 1px solid #ced4da;
      border-top-right-radius: 4px;
      left: 3px;
      position: relative;
      width: 75px;
      z-index: 10;
    }

    input {
      background: #f3f3f3;
      border: 1px solid #b8b8d2;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-family: PoppinsSemiBold, sans-serif;
      height: 40px;
      padding-left: 15px;
    }
  }

  &-conversion-amount {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    margin-left: 185px;
  }

  .payment-button-container {
    .right {
      margin-right: 10px;
    }
  }
}

.promotion-dialog {
  &-label {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-heading {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0;
  }

  &-dropdown.p-dropdown {
    background: #f3f3f3;
    border: 1px solid #b8b8d2;
    border-radius: 10px;
    font-family: PoppinsSemiBold, sans-serif;
    height: 40px;
    margin-top: 5px;

    span:nth-child(3) {
      font-family: PoppinsSemiBold, sans-serif;
    }
  }

  &-input {
    background: #f3f3f3;
    border: 1px solid #b8b8d2;
    border-radius: 10px;
    font-family: PoppinsSemiBold, sans-serif;
    height: 40px;
  }

  &-btn.p-button {
    background: #fd01f7;
    border: 1px solid #fd01f7;
    border-radius: 10px;
    color: #fff;
    font-family: PoppinsRegular, sans-serif;
    height: 40px;
    width: 200px;

    &:hover {
      background: #fd01f7;
      border: 1px solid #fd01f7;
    }

    .p-button-icon {
      font-size: 1.25rem;
    }

    span {
      font-size: 15px;
    }
  }

  &-cell-btn.p-button {
    background: #fd01f7;
    border: 1px solid #fd01f7;
    border-radius: 10px;
    color: #fff;
    font-family: PoppinsRegular, sans-serif;
    height: 40px;
    width: 215px;

    &:hover {
      background: #fd01f7;
      border: 1px solid #fd01f7;
    }

    .p-button-icon {
      font-size: 1.25rem;
    }

    span {
      font-size: 15px;
    }
  }

  &-icon {
    border: 2px solid lightgrey;
    border-radius: 10px;
    padding: 5px;
  }

  &-icon-selected {
    border: 2px solid #fd01f7;
  }

  &-cancel-btn.p-component.p-button {
    background: #3d5cff;
    border: 1px solid #3d5cff;
    border-radius: 8px;
    color: #fff;
    font-family: PoppinsMedium, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    text-align: center;
    width: 120px;
  }

  &-save-btn.p-component.p-button {
    background: #fd01f7;
    border: 1px solid #fd01f7;
    border-radius: 8px;
    color: #fff;
    font-family: PoppinsMedium, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    text-align: center;
    width: 120px;
  }

  .p-inputgroup {
    .p-inputgroup-addon.vula {
      border-bottom-right-radius: 4px;
      border-right: 1px solid #ced4da;
      border-top-right-radius: 4px;
      left: 3px;
      position: relative;
      z-index: 10;
    }

    input {
      background: #f3f3f3;
      border: 1px solid #b8b8d2;
      border-radius: 10px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-family: PoppinsSemiBold, sans-serif;
      height: 40px;
      padding-left: 15px;
    }
  }

  &-react-player {
    border: 2px solid lightgrey;
    border-radius: 10px;
    cursor: pointer;
    margin: 5px 10px;
    padding: 10px;
  }

  &-react-player-selected {
    border: 2px solid #fd01f7;
  }

  &-media-container {
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 10px;
  }
}

.payouts {
  &-heading {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 35px;
    letter-spacing: 0;
  }

  &-schedule-icon {
    height: 32px;
  }

  &-vc-currency-icon {
    height: 14px;
    margin-right: 8px;
  }

  &-vc-label,
  &-zar-label {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 25px;
    letter-spacing: 0;
  }

  &-vc-input input,
  &-zar-input input {
    background: #f3f3f3;
    border: 0 solid #b8b8d2;
    border-radius: 10px !important;
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 25px;
    letter-spacing: 0;
    text-align: right;
  }

  &-rate-text {
    color: #858597;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-payout-button {
    background: #3d5cff;
    border-color: #3d5cff;
    border-radius: 10px;
    color: #fff;
    font-family: PoppinsMedium, sans-serif;
    font-size: 18px;
    height: 55px;
    letter-spacing: 0;
    text-align: center;
  }

  &-calculation-label {
    color: #858597;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-calculation-value {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: right;
  }

  &-input-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-slider {
    height: 5px;
    margin-top: 10px;
    width: 90%;
  }
}

.wallets {
  &-back-container {
    margin-bottom: 34.5px;
  }

  &-back-icon {
    color: #1f1f39;
    font-size: 1.25em;
    margin-right: 10px;
    margin-top: 5px;
  }

  &-back-text {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-heading {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
  }

  &-heading-icon {
    cursor: pointer;
    font-size: 1.25em;
    margin-left: 30px;
    margin-top: 8px;
  }

  &-cell-label,
  &-discount-label {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-cell-number,
  &-discount-value {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 50px;
  }

  &-balance-label {
    color: #1f1f39;
    font-family: PoppinsMedium, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    margin-right: 50px;
  }

  &-balance-amount {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
  }

  &-balance-icon {
    margin-bottom: 13px;
  }

  &-add-funds-btn {
    background: #3d5cff;
    border-color: #3d5cff;
    border-radius: 8px;
    color: #fff;
    font-family: PoppinsMedium, sans-serif;
    font-size: 16px;
    height: 55px;
    letter-spacing: 0;
    margin-top: 15px;
    max-width: 190px;
    text-align: center;
    width: 190px;
  }

  &-discount-text {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &-discount-input {
    margin-left: 30px;
    width: 35%;
  }

  &-dialog-label {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.merchants {
  &-heading-icon {
    cursor: pointer;
    font-size: 1.25em;
    margin-left: 30px;
    margin-top: 8px;
  }

  &-heading {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
  }

  &-td {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 20px;
  }

  &-container {
    // padding: 14px;
    padding-bottom: 0;
  }
}

.merchant-settings {
  &-heading-icon {
    cursor: pointer;
    font-size: 1.25em;
    margin-left: 30px;
    margin-top: 8px;
  }

  &-heading {
    color: #1f1f39;
    font-family: PoppinsSemiBold, sans-serif;
    font-size: 20px;
    letter-spacing: 0;
  }

  &-td {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    min-width: 160px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 20px;
  }

  &-container {
    display: inline-flex;
    padding: 14px;
    padding-bottom: 0;
    width: 100%;
  }
}

.p-dialog-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.d-flex-row {
  display: flex;
}

.d-flex-end {
  justify-content: flex-end;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

.floatlabel-demo {
  .p-field {
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

.layout-content-wrapper,
.login-wrapper {
  background-color: #f8f9fa;
}

.login-label {
  margin-bottom: 10px;
  margin-left: 25px;
  margin-right: auto;
}

.logo-img {
  width: 100%;
}

.input-demo {
  .p-multiselect {
    min-width: 15rem;
  }

  .multiselect-custom {
    .country-item {
      align-items: center;
      display: flex;

      span.flag {
        height: 12px;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 18px;
      }
    }

    .country-item-value {
      background-color: #2196f3;
      border-radius: 3px;
      color: #fff;
      display: inline-flex;
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      vertical-align: top;
    }
  }
}

.list-demo {
  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem;
  }

  .product-category-icon {
    margin-right: 0.5rem;
    vertical-align: middle;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  .product-list-item {
    align-items: center;
    display: flex;
    padding: 1rem;
    width: 100%;

    img {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
      margin-right: 2rem;
      width: 150px;
    }

    .product-list-detail {
      flex: 1 1 0;
    }

    .p-rating {
      margin: 0 0 0.5rem;
    }

    .product-price {
      align-self: flex-end;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .product-list-action {
      display: flex;
      flex-direction: column;
    }

    .p-button {
      margin-bottom: 0.5rem;
    }
  }

  .product-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .product-grid-item {
    border: 1px solid var(--surface-d);
    margin: 0.5em;

    .product-grid-item-top,
    .product-grid-item-bottom {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    img {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
      margin: 2rem 0;
      width: 75%;
    }

    .product-grid-item-content {
      text-align: center;
    }

    .product-price {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media screen and (width <= 576px) {
    .product-list-item {
      flex-direction: column;

      img {
        margin: 2rem 0;
        width: 75%;
      }

      .product-list-detail {
        text-align: center;
      }

      .product-price {
        align-self: center;
      }

      .product-list-action {
        display: flex;
        flex-direction: column;
      }

      .product-list-action {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
      }
    }
  }
}

.media-demo {
  .product-item {
    .product-item-content {
      border: 1px solid var(--surface-d);
      border-radius: 3px;
      margin: 0.3rem;
      padding: 2rem 0;
      text-align: center;
    }

    .product-image {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
      width: 50%;
    }
  }
}

.menu-demo {
  .stepsdemo-content {
    padding: 1em 0;

    p {
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      margin: 0;
      vertical-align: middle;
    }

    i {
      font-size: 1.5em;
      margin: 0;
      vertical-align: middle;
    }
  }

  .contextmenu-image {
    width: 100%;
  }
}

.messages-demo {
  .p-field > label {
    width: 125px;
  }

  .p-inputtext {
    margin-right: 0.5rem;
  }
}

.misc-demo {
  .badges {
    .p-badge,
    .p-tag {
      margin-right: 0.5rem;
    }
  }

  .p-button {
    margin-right: 0.5rem;
  }

  .p-overlay-badge {
    margin-right: 2rem;
  }

  @keyframes p-progress-spinner-color {
    100%,
    0% {
      stroke: #2196f3;
    }

    40% {
      stroke: #ec407a;
    }

    66% {
      stroke: #66bb6a;
    }

    80%,
    90% {
      stroke: #ffa726;
    }
  }
}

.overlay-demo {
  p {
    line-height: 1.5;
    margin: 0;
  }

  .product-image {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    width: 50px;
  }
}

.panel-demo {
  .p-toolbar {
    flex-wrap: wrap;
    overflow: auto;
  }

  p {
    line-height: 1.5;
    margin: 0;
  }

  .p-card .p-card-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.table-demo {
  .p-progressbar {
    background-color: #d8dadc;
    height: 0.5rem;

    .p-progressbar-value {
      background-color: #607d8b;
    }
  }

  .p-datatable .p-column-filter {
    display: none;
  }

  .table-header {
    display: flex;
    justify-content: space-between;

    @media (width <= 768px) {
      flex-direction: column;
    }
  }

  .p-datatable.p-datatable-customers {
    .p-datatable-tbody {
      > tr {
        border-bottom: 1px solid var(--surface-d);
      }
    }

    .p-datatable-header {
      font-size: 1.5rem;
      padding: 1rem;
      text-align: left;
    }

    .p-paginator {
      padding: 1rem;
    }

    .p-datatable-thead > tr > th {
      text-align: left;
    }

    .p-datatable-tbody > tr > td {
      cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
      text-transform: uppercase;
    }
  }

  /* Responsive */
  .p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .customer-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;

    &.status-qualified {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-unqualified {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-negotiation {
      background: #feedaf;
      color: #8a5340;
    }

    &.status-new {
      background: #b3e5fc;
      color: #23547b;
    }

    &.status-renewal {
      background: #eccfff;
      color: #694382;
    }

    &.status-proposal {
      background: #ffd8b2;
      color: #805b36;
    }
  }

  .p-progressbar-value.ui-widget-header {
    background: #607d8b;
  }

  @media (width <= 640px) {
    .p-progressbar {
      margin-top: 0.5rem;
    }
  }

  .product-image {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    width: 100px;
  }

  .orders-subtable {
    padding: 1rem;
  }

  .product-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  .order-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;

    &.order-delivered {
      background: #c8e6c9;
      color: #256029;
    }

    &.order-cancelled {
      background: #ffcdd2;
      color: #c63737;
    }

    &.order-pending {
      background: #feedaf;
      color: #8a5340;
    }

    &.order-returned {
      background: #eccfff;
      color: #694382;
    }
  }

  @media screen and (width <= 960px) {
    .p-datatable {
      &.p-datatable-customers {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          > td {
            border: 0 none !important;
            clear: left;
            display: block;
            float: left;
            text-align: left;
            width: 100% !important;

            .p-column-title {
              display: inline-block;
              font-weight: bold;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              min-width: 30%;
              padding: 0.4rem;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.crud-demo {
  padding-left: 40px;
  padding-right: 40px;

  .table-header {
    flex-direction: column;

    @media screen and (width >= 768px) {
      align-items: center;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
    }
  }

  .product-image {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    width: 100px;
  }

  .p-toolbar {
    display: flex;
    flex-wrap: wrap;
  }

  .p-dialog .product-image {
    display: block;
    margin: 0 auto 2rem;
    width: 150px;
  }

  .confirmation-content {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .product-badge {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }

  /* Responsive */
  .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  @media screen and (width <= 960px) {
    .p-datatable {
      &.datatable-responsive {
        .p-datatable-thead > tr > th,
        .p-datatable-tfoot > tr > td {
          display: none !important;
        }

        .p-datatable-tbody > tr {
          border-bottom: 1px solid var(--surface-d);

          > td {
            align-items: center;
            border: 0 none !important;
            clear: left;
            display: flex;
            float: left;
            text-align: left;
            width: 100% !important;

            .p-column-title {
              display: inline-block;
              font-weight: bold;
              margin: -0.4rem 1rem -0.4rem -0.4rem;
              min-width: 30%;
              padding: 0.4rem;
            }

            .p-progressbar {
              margin-top: 0.5rem;
            }

            .actions {
              display: flex;
              flex-grow: 1;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.elevation-demo {
  .box {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-weight: 600;
    justify-content: center;
    margin: 2rem;
    min-height: 100px;
    min-width: 150px;
  }
}

.flexbox-demo {
  .p-d-flex > div,
  .box {
    background-color: var(--surface-e);
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
      0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    padding: 1rem;
    text-align: center;
  }

  .p-d-flex > div {
    width: 8rem;
  }

  i:not([class~='pi']) {
    background-color: #fff;
    border-radius: 3px;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0 2px;
    padding: 2px 4px;
  }
}

.flexgrid-demo {
  background-color: #f4f4f7;
  border-radius: 2px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 26%);
  padding: 20px;

  .box {
    background-color: var(--surface-e);
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
      0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    font-size: 1.5rem;
    padding: 1.25rem;
    text-align: center;
  }

  .box-stretched {
    height: 100%;
  }

  .vertical-container {
    background: var(--surface-d);
    border-radius: 4px;
    height: 200px;
    margin: 0;
  }

  .nested-grid .p-col-4 {
    padding-bottom: 1rem;
  }

  .dynamic-box-enter-active,
  .dynamic-box-leave-active {
    transition: all 0.5s;
  }

  .dynamic-box-enter-from,
  .dynamic-box-leave-to {
    opacity: 0;
  }

  .dynamic-box-enter-from,
  .dynamic-box-leave-to {
    transform: translateX(30px);
  }

  p {
    margin: 0;
  }
}

.icons-demo {
  .icon-filter {
    margin: 1rem 0 1.5rem;
    padding: 1rem;
    width: 100%;
  }

  .icons-list {
    color: var(--text-color);
    text-align: center;

    .p-md-2 {
      padding: 1em;
    }
  }

  .icons-list i {
    color: var(--text-color-secondary);
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
}

.spacing-demo {
  .demo-container {
    border: 1px solid var(--surface-d);
  }
}

.text-demo {
  .demo-container {
    border: 2px solid #dee2e6;
  }
}

pre[class*='language-'] {
  &::before,
  &::after {
    display: none !important;
  }

  code {
    background: var(--surface-b) !important;
    border-left: 10px solid var(--surface-d) !important;
    box-shadow: none !important;
    color: var(--text-color);
    font-size: 14px;
    margin: 1em 0;

    .token {
      &.tag,
      &.keyword {
        color: #2196f3 !important;
      }

      &.attr-name,
      &.attr-string {
        color: #2196f3 !important;
      }

      &.attr-value {
        color: #4caf50 !important;
      }

      &.punctuation {
        color: var(--text-color);
      }

      &.operator,
      &.string,
      &.entity,
      &.url,
      &.variable {
        background: transparent;
      }
    }
  }
}

.left-rounded input {
  border-radius: 4px 0 0 4px;
}

.timeline-demo {
  .custom-marker {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
    z-index: 1;
  }

  .p-timeline-event-content,
  .p-timeline-event-opposite {
    line-height: 1;
  }

  @media screen and (width <= 960px) {
    .customized-timeline {
      .p-timeline-event:nth-child(even) {
        flex-direction: row !important;

        .p-timeline-event-content {
          text-align: left !important;
        }
      }

      .p-timeline-event-opposite {
        flex: 0;
      }

      .p-card {
        margin-top: 1rem;
      }
    }
  }
}

@media screen and (width <= 960px) {
  .fc-toolbar {
    &.fc-header-toolbar {
      flex-wrap: wrap;
    }
  }
}

.max-div {
  height: 100vh;
  line-height: 100vh;
  text-align: center;
  width: 100%;
}

.vulacoin-currency {
  span {
    display: inline-block;
    margin: 3px 0 3px 3px;

    img {
      height: 25px;
    }

    .vc-currency-img {
      height: 11px;
      position: relative;
      top: 0.5px;
    }
  }

  p {
    display: inline-block;
    vertical-align: super;
  }
}
