@import '../sass/variables/layout/_common'; /* stylelint-disable-line */

.admin-retailer-dialog {
  border-radius: 10px;
}

.custom-highlight .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f6f6f6;
  cursor: default;
}

.no-hover-effect .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #fff;
  cursor: default;
}

.no-hover-effect .p-datatable-hoverable-rows .p-selectable-row {
  background: #fff;
  cursor: default;
}

@media screen and (max-width: $tabletBreakpoint) {
  #transaction-container-admin {
    top: 0;
  }
    
  .transaction-panel {
    border-radius: 0;
    min-width: 130px;
        
    .p-dropdown-items-wrapper {
      z-index: 21;

      .p-dropdown-items {
        padding: 0;

        .p-dropdown-item {
          color: #6c757d;
          font-family: PoppinsRegular, sans-serif;
          font-size: 18px;
          font-weight: bold;
        }

        .p-highlight {
          color: #f72fdc;
        }
      }
    }
  }

  .mobile-tablet-slim {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile-tablet-padding-0 {
    padding: 0;
  }

  .tablet-full-width {
    width: 100% !important;
  }

  .mobile-tablet-width-full {
    width: 100%;
  }

  .mobile-tablet-top-1 {
    top: 1rem;
  }

  .mobile-tablet-top-padding-1 {
    padding-top: 1rem;
  }

  .mobile-tablet-top-margin-1 {
    margin-top: 1rem;
  }

  .mobile-tablet-top-margin-5 {
    margin-top: 5rem;
  }

  .mobile-tablet-left-margin-0 {
    margin-left: 0;
  }

  .mobile-tablet-left-margin-1 {
    margin-left: 1rem;
  }

  .mobile-tablet-bottom-margin-1 {
    margin-bottom: 1rem;
  }

  .mobile-tablet-bottom-margin-half {
    margin-bottom: 0.5rem;
  }

  .desktop-tablet-align-right {
    text-align: inherit !important;
  }

  .dialog-mobile-tablet-padding-0 {
    .p-dialog-content {
      padding: 0;
    }
  }
}

@media screen and (max-width: $phoneBreakpoint) {
  .admin-retailer-dialog {
    width: 90% !important;
  }

  .mobile-right-margin-1 {
    margin-right: 1rem;
  }

  .hide-paginator .p-paginator-bottom {
    display: none;
  }
}

.balance-hover:hover {
  background: #e9ecef;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: -2px;
  padding-bottom: 2px;
}

.layout-content-wrapper-admin-retailer {
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .hide-paginator .p-paginator-bottom {
    visibility: hidden;
  }
    
  .datatable-card-wrapper .card .datatable-responsive .p-datatable-thead {
    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }
    
  .networks-external-header {
    margin-top: 17px;
    top: 41px;
  }
    
  .merchant-tabs .floating-right-container,
  .transaction-tabs .floating-right-container {
    float: right;
    position: relative;
    top: 35px;
    z-index: 100;
  }

  .p-datatable-thead .desktop-align-right {
    text-align: right;
  }

  .layout-content {
    .blended-rate-card {
      height: 520px;

      .revenue-chart-container {
        margin-top: 50px;

        #revenue-chart {
          height: 100%;
        }
      }
    }

    .blended-rate {
      margin-top: 0 !important;
    }
  }

  .transaction-table-wrapper {
    position: relative;
    top: -2.5px;
  }

  @media screen and (min-width: $tabletBreakpoint) {
    .hide-only-desktop {
      display: none;
    }

    .summary-table-wrapper {
      .p-datatable {
        min-width: 1400px;
      }
    }
  }

  @media screen and (max-width: $tabletBreakpoint) {
    .networks-external-header {
      margin-top: 0;
      top: 0;
    }
        
    .merchant-tabs .p-tabview,
    .transaction-tabs .p-tabview {
      margin-top: -50px;
    }

    .merchant-tabs .floating-right-container,
    .transaction-tabs .floating-right-container {
      padding-bottom: 6px;
      top: -20px !important;

      .p-input-icon-left input {
        border-radius: 10px;
      }
    }
        
    .summary-table-wrapper {
      position: relative;
      top: -18.5px;
    }

    .p-tabview-panels {
      background: none;

      .card {
        background: none;
      }
    }
        
    .merchant-tabs .p-unselectable-text,
    .transaction-tabs .p-unselectable-text {
      display: none;
    }

    .merchant-tabs .p-button,
    .transaction-tabs .p-button {
      position: relative;
      right: 5px;
      top: 2px;
    }

    .transaction-table-wrapper {
      position: relative;
      top: -18.5px;

      .card {
        background: none;
      }
    }

    .networks-drop-down {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-width: 0;
      min-width: 130px;
      top: -2px;
      z-index: 20;

      .p-inputtext {
        color: #f72fdc;
        font-family: PoppinsRegular, sans-serif;
        font-size: 18px;
        font-weight: bold;
        padding-left: 14px;
      }
    }

    .networks-drop-down:not(.p-disabled).p-focus {
      box-shadow: none !important;
    }
        
    .transaction-drop-down {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-width: 0;
      min-width: 130px;
      top: -22px;
      z-index: 20;

      .p-inputtext {
        color: #f72fdc;
        font-family: PoppinsRegular, sans-serif;
        font-size: 18px;
        font-weight: bold;
        padding-left: 14px;
      }
    }
        
    .transaction-drop-down:not(.p-disabled).p-focus {
      box-shadow: none !important;
    }
        
    .p-tabview .p-tabview-panels {
      padding: 0;
    }

    .merchant-tabs .floating-right-container,
    .transaction-tabs .floating-right-container {
      position: relative;
      right: 0;
      top: 7px;
    }
        
    .merchant-tabs .p-tabview .p-tabview-nav .p-unselectable-text .p-tabview-nav-link,
    .transaction-tabs .p-tabview .p-tabview-nav .p-unselectable-text .p-tabview-nav-link {
      background: none;
    }
        
    .merchant-tabs .p-tabview .p-tabview-nav,
    .transaction-tabs .p-tabview .p-tabview-nav {
      background: none;
      border: 0;
      border-width: 0;
      margin-bottom: 2px;
      margin-top: 3rem;
    }

    .merchant-tabs .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
    .transaction-tabs .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: #fff;
      border-bottom-width: 0;
      box-shadow: none;
    }

    .merchant-tabs .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link .p-tabview-title,
    .transaction-tabs .p-tabview .p-tabview-nav .p-highlight .p-tabview-nav-link .p-tabview-title {
      color: #f72fdc;
      font-family: PoppinsRegular, sans-serif;
      font-size: 18px;
      font-weight: bold;
      left: 0;
      position: relative;
      top: -2px;
      vertical-align: top;
    }

    .merchant-tabs .p-tabview .p-tabview-nav li .p-tabview-nav-link,
    .transaction-tabs .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background: #fff;
      border: solid #dee2e6;
      border-color: transparent transparent #dee2e6;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-width: 0 0 2px;
      border-bottom-width: 0;
      color: #6c757d;
      font-weight: 600;
      margin: 0 0 -2px;
      padding: 1rem;
      transition: box-shadow 0.2s;
    }

    .merchant-tabs .p-tabview .p-tabview-nav .p-tabview-nav-link .p-tabview-title,
    .transaction-tabs .p-tabview .p-tabview-nav .p-tabview-nav-link .p-tabview-title {
      font-family: PoppinsRegular, sans-serif;
      font-size: 18px;
      left: 0;
      position: relative;
      top: -2px;
      vertical-align: top;
    }
        
    .external-header {
      height: 40px;
      position: relative;
            
      .floating-right-container {
        float: right;

        input {
          border-radius: 10px;
        }
      }

      .p-input-icon-left {
        float: right;
      }
    }

    .layout-content {
      .blended-rate-card {
        .revenue-chart-container {
          margin-top: 0;

          #revenue-chart {
            height: 100%;
          }
        }
      }
    }

    .external-header .floating-right-container .p-button-icon-only {
      background: none;
      border: 0;
      color: black;
      margin-bottom: 2px;
      margin-right: 5px;
    }

    .external-header .floating-right-container .p-button-icon-only:hover {
      background: none;
      color: black;
    }

    .p-button .p-button-icon-left {
      margin: 0;
    }

    .p-fileupload-choose {
      background: none;
      border: 0;
      color: black;
      padding: 7px;
    }

    .p-fileupload-choose:hover {
      background: none;
      box-shadow: none;
      color: black;
    }

    .merchant-settings-container {
      display: inline-block;
    }

    .crud-demo .p-col-12 .datatable-card-wrapper {
      .card {
        border-radius: 10px;
        box-shadow: 0 6px 5px 0 rgb(0 0 0 / 3%);
        padding: 0;

        .p-datatable-wrapper table .p-datatable-tbody tr td {
          padding-bottom: 10px;
          padding-top: 10px;;
        }
                
        .p-datatable-wrapper table .p-datatable-tbody tr .firstColumn { /* stylelint-disable-line */
          background: #fff;
          border-top: 2px solid #555 !important;
        }

        .p-datatable-wrapper table .p-datatable-tbody tr .action-bar {
          padding-top: 0;
        }
      }
    }

    .menu-burger {
      background: none;
      border: 0;
      border-radius: 12px;
      box-shadow: none;
      color: grey;
      display: inherit;
      height: 36px;
      left: 2rem;
      padding: 0;
      padding-left: 4px;
      padding-top: 2px;
      position: fixed;
      top: 19px;
      transition: all 0.3s;
      width: 38px;
      z-index: 1100 !important;
            
      .pi-bars {
        font-size: 26px !important;
      }
    }
  
    .active-burger {
      background: none;
      border: 0;
      box-shadow: none;
      color: grey;
      display: inherit;
      height: 36px;
      left: 2rem;
      padding-left: 4px;
      padding-top: 2px;
      position: fixed;
      top: 19px;
      transition: all 0.3s;
      width: 38px;
      z-index: 1100 !important;
          
      .pi-bars {
        font-size: 30px !important;
      }
    }

    .hide-only-table-mobile {
      display: none;
    }
  }

  @media screen and (max-width: $phoneBreakpoint) {
    .networks-external-header .p-inputtext {
      width: 130px;
    }
        
    .networks-drop-down {
      left: 0;
      top: -2px;
    }
        
    .transaction-drop-down {
      left: 0;
      top: -22px;
    }
        
    .layout-content {
      padding-left: 0;
      padding-right: 0;

      .blended-rate-card {
        height: fit-content;

        .revenue-chart-container {
          height: fit-content;
          margin-top: 0;

          #revenue-chart {
            height: fit-content;
          }
        }
      }
    }
        
    .page-header {
      padding-left: 1rem;
    }

    .page-sub-header {
      margin-top: 1rem !important;
      padding-left: 1rem;
    }

    .card {
      border-radius: 0;
      box-shadow: 0 6px 5px 0 rgb(0 0 0 / 3%);
    }
        
    .crud-demo {
      padding: 0;
    }

    .merchant-tabs
    .transaction-tabs {
      position: relative;
    }

    .external-header .floating-right-container .p-button-icon-only {
      background: none;
      border: 0;
      color: black;
      margin-bottom: 2px;
      margin-right: 5px;
    }

    .merchant-tabs .floating-right-container,
    .transaction-tabs .floating-right-container {
      position: absolute;
      right: 0;
      top: -40px;
    }

    .mobile-slim {
      padding-left: 0;
      padding-right: 0;
    }

    .mobile-full-width {
      width: 100% !important;
    }

    .mobile-top-margin {
      margin-top: 7rem;
    }

    .crud-demo .p-col-12 .datatable-card-wrapper {
      .card {
        border-radius: 0;
        box-shadow: 0 6px 5px 0 rgb(0 0 0 / 3%);
        padding: 0;

        .p-datatable-wrapper table .p-datatable-tbody tr td {
          padding-bottom: 10px;
          padding-top: 10px;;
        }
                
        .p-datatable-wrapper table .p-datatable-tbody tr .firstColumn { /* stylelint-disable-line */
          background: #fff;
          border-top: 2px solid #555 !important;
        }

        .p-datatable-wrapper table .p-datatable-tbody tr .action-bar {
          padding-top: 0;
        }
      }
    }

    .wallets-back-container {
      margin-left: 1rem;
    }

    .mobile-block {
      display: block;
    }

    .mobile-justify-none {
      justify-content: initial;
    }

    .mobile-justify-start {
      justify-content: start;
    }
        
    .menu-burger {
      background: none;
      border: 0;
      border-radius: 12px;
      box-shadow: none;
      color: grey;
      height: 36px;
      left: 2rem;
      padding-left: 4px;
      padding-top: 2px;
      position: fixed;
      top: 19px;
      transition: all 0.3s;
      width: 38px;
      z-index: 1100 !important;
            
      .pi-bars {
        font-size: 26px !important;
      }
    }
  
    .active-burger {
      background: none;
      border: 0;
      box-shadow: none;
      color: grey;
      height: 36px;
      left: 2rem;
      padding-left: 4px;
      padding-top: 2px;
      position: fixed;
      top: 19px;
      transition: all 0.3s;
      width: 38px;
      z-index: 1100 !important;
          
      .pi-bars {
        font-size: 30px !important;
      }
    }
  }
}
