.vula-input {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .input-box {
    align-items: center;
    align-self: stretch;
    background: var(--base-white, #fff);
    border: 1px solid var(--gray-300, #d0d5dd);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--gray-900, #101828);
    display: flex;
    font-family: PoppinsRegular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    line-height: 20px;
    min-height: 44px;
    outline: none;
    padding: 10px 14px;
    width: 100%;
  }

  .input-box.empty {
    color: var(--gray-400, #98a2b3);
    font-family: PoppinsRegular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .input-box:focus::placeholder {
    color: transparent;
  }

  .input-label {
    text-align: left;;
  }
}
