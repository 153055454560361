.login-body {
  background: $bodyBg;
  color: $textColor;

  .login-wrapper {
    @include flex();
    height: 100vh;

    .login-panel {
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 40px 20px;
      @include flex();
      @include flex-align-center();
      @include flex-justify-between();
      @include flex-direction-column();

      .logo {
        height: 280px;
      }

      .login-form {
        min-width: 300px;
        @include flex();
        @include flex-align-center();
        @include flex-direction-column();
        > p {
          font-weight: 600;
          margin: 0;
          color: $textSecondaryColor;
          margin-bottom: 32px;

          > a {
            color: $linkColor;
            cursor: pointer;
          }
        }

        > .p-password {
          width: 85%;
          max-width: 310px;
          margin-bottom: 20px;

          .p-password-input {
            width: 100%;
          }
        }

        > input {
          width: 85%;
          max-width: 310px;
          margin-bottom: 20px;
        }

        > button {
          width: 85%;
          max-width: 310px;
        }
      }

      p {
        font-weight: 600;
        margin: 0;
        color: $textSecondaryColor;

        > a {
          color: $linkColor;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .login-body {
    .login-wrapper {
      .login-panel {
        width: 100%;
        z-index: 2;
      }
    }
  }
}
