@import "_common";

$bodyBg: #fff !default;
$textColor: #495057 !default;
$textSecondaryColor: #6c757d !default;
$dividerColor: #dee2e6 !default;
$itemHoverBg: #e9ecef !default;
$focusShadow: 0 0 0 0.2rem #bbdefb !default;
$linkColor: #1976d2 !default;
$overlayBorder: 0 none !default;
$overlayShadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;
$overlayTopMenuShadow: 0 2px 4px -1px rgba(0, 0, 0, 0), 0 6px 5px 0 rgba(0, 0, 0, 0.03), 0 1px 10px 0 rgba(0, 0, 0, 0) !default;

//card
$cardBg: #ffffff !default;
$cardBorder: 0 none !default;

//transactiontabs
$transactiontabsBackgroundColor: none;
$transactiontabsActiveBackgroundColor: $cardBg;
$transactiontabsTextColor: $textColor;

//topbar menu
$topbarmenuWidth: 100%;
$topbarmenuHeight: 7rem;
$topbarmenuBg: #ffffff !default;
$topbarmenuBottomBorder: none;
$topbarmenuTextColor: #1F1F39;
$topbarmenuFontSize: 16px !default;
$topbarmenuIconBg: #f8f9fa !default;
$topbarmenuIconHoverBg: #e9ecef !default;

//topbar
$topbarBg: #ffffff !default;
$topbarBottomBorder: 1px solid $dividerColor !default;
$topbarTextColor: $textColor !default;
$topbarIconBg: #f8f9fa !default;
$topbarIconHoverBg: #e9ecef !default;

//accent
$accentColor: #1976d2 !default;
$accentTextColor: #ffffff !default;

//sidebar
$sidebarWidth: 16rem !default;
$slimSidebarWidth: 6rem !default;

//search
$searchBg: #adb5bd !default;
$searchBorder: 0 none !default;

//footer
$footerBg: #ffffff !default;
$footerBorder: 1px solid $dividerColor !default;

//sidebar right
$rightSidebarWidth: 16rem !default;
$rightSidebarBg: #ffffff !default;
