.layout-topbar-menu-darkgray {
  $topbarmenuBgColor: #343a40;
  $topbarmenuBgColorAlt: #2e3439;
  $topbarmenuBorder: 0 none;
  $appNameColor: #ffffff;
  $menuSeparatorBorder: 1px solid rgba(255, 255, 255, 0.2);
  $menuitemRootTextColor: rgba(255, 255, 255, 0.4);
  $menuitemTextColor: rgba(255, 255, 255, 0.8);
  $menuitemHoverBg: #4b545c;
  $menuitemActiveBg: #3f474e;
  $menuitemTextActiveColor: #ffffff;
  $menuitemFocusShadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);

  @import "../_topbarmenu_theme_core";
}
