.vulacoin-button {
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.1s;
  
  @media (hover: hover) {
    &:hover:enabled {
      cursor: pointer;
      opacity: 0.8; 
    }
  }

  &:active:enabled {
    transform: scale(0.98); 
  }

  &-icon {
    height: 20px;
    width: 20px;
  }
}

.vulacoin-button.standard-100 {
  align-items: center;
  background: var(--vcblue-600, #3e5cff);
  border: 0;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 18px;
  width: 100%;
}

.vulacoin-button.standard-200 {
  align-items: center;
  background: var(--base-white, #fff);
  border: 1px solid var(--base-black, #222);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 18px;
  width: 100%;
}

.vulacoin-button.text-only-small {
  align-items: center;
  background: none;
  border: 0;
  box-shadow: none;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 100%;
  width: fit-content;
    
  .vulacoin-text {
    width: fit-content;
  }
}

.vulacoin-button.text-only {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 12px 18px;
  width: 100%;
}

.vulacoin-button .vulacoin-text {
  margin: 0;
}

.vulacoin-button.disabled {
  background: var(--gray-300, #d0d5dd);
  border: 0;
}

.vulacoin-button.secondary {
  background: var(--base-white, #fff);
  border: 1px solid var(--base-black, #222);
}
