.layout-dashboard {
    .orders {
        h4 {
            margin-bottom: 20px;
        }

        .p-button {
            margin-top: -20px;
        }

        .order-tabs {
            margin-bottom: 1rem;

            .order-tab {
                padding: 1rem 2rem 2rem 1rem;
                position: relative;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                }

                i {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                .order-label {
                    margin-left: 0.25rem;
                    vertical-align: middle;
                }

                .stat-detail-icon {
                    position: absolute;
                    right: 1rem;
                    top: 2.25rem;
                    height: 1rem;
                    width: 1rem;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    width: 90%;
                }
            }
        }
    }

    .overview-chart {
        overflow: auto;

        .date-input {
            padding-top: 12px;
            padding-bottom: 12px;

            &-label {
                margin-bottom: 4px;
                font-size: 14px;
                height: fit-content;
            }

            &-box {
                width: 100% !important;
                height: 50px;
                font-family: 'PoppinsRegular';
                font-size: 16px;
                color: #1f1f39;
                border-radius: 10px;
                border: 1px solid #ced4da;
                padding-left: 1rem;
                padding-right: 1rem;
                -webkit-appearance: none;
                -moz-appearance: none;
                background: #ffffff;
            }

            input::-webkit-date-and-time-value {
                text-align: left;
            }
        }
    }

    .dashbard-demo-dropdown {
        min-width: 8rem;
        margin-left: auto;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    /* Responsive */
    .p-datatable-products .p-datatable-tbody>tr>td .p-column-title {
        display: none;
    }

    @media screen and (max-width: 960px) {
        .p-datatable {
            &.p-datatable-products {

                .p-datatable-thead>tr>th,
                .p-datatable-tfoot>tr>td {
                    display: none !important;
                }

                .p-datatable-tbody>tr {
                    border-bottom: 1px solid var(--surface-d);

                    >td {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        border: 0 none !important;
                        width: 100% !important;
                        float: left;
                        clear: left;
                        border: 0 none;

                        .p-column-title {
                            padding: .4rem;
                            min-width: 30%;
                            display: inline-block;
                            margin: -.4rem 1rem -.4rem -.4rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}