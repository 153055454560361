.cell-input {
  display: flex;
  width: 100%;

  select {
    background: var(--base-white, #fff);
    border: 1px solid var(--gray-300, #d0d5dd);
    border-radius: 6px;
    flex-shrink: 0;
    font-size: 24px;
    height: 44px;
    left: 4px;
    margin-left: -4px;
    position: relative;
    text-align: center;
    width: 76px;
  }

  option {
    margin: auto;
  }

  .select-items div,
  .select-selected {
    border: 1px solid transparent;
    border-color: transparent transparent rgb(0 0 0 / 10%);
    color: #fff;
    cursor: pointer;
    padding: 8px 16px;
  }

  .input-box {
    border-bottom-left-radius: 0;
    border-left-width: 0;
    border-top-left-radius: 0;
  }

  .p-inputtext.p-component.cell-input-text:focus {
    background: #fff !important;
  }
}
