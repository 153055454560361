@media screen and (min-width: $tabletBreakpoint + 1) {
    .layout-topbar-menu {
        width: 100%;
        height: 7rem;
        background: white;
        position: fixed;
        top: 0px;
        left: 0px;
        box-shadow: $overlayTopMenuShadow;
        @include flex();
        @include flex-direction-column();
        user-select: none;
        transition: transform $animationDuration $animationTimingFunction;

        .logo {
            text-align: left;
            width: fit-content;
            display: block;
            outline: 0 none;
            padding: 0rem;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 2rem;
            margin-right: 0rem;
            transition: background-color $transitionDuration, box-shadow $transitionDuration;
        }

        .logo-img {
            width: 12.35rem;
            height: 4rem;
            margin-top: 0.6rem;
            margin-right: .0rem;
        }

        .app-name {
            font-size: 1.5rem;
            letter-spacing: .2px;
        }

        .layout-menu-container-topbar {
            flex: 1;
            padding: 2.2rem 4rem 1rem 1rem;
            width: auto;
            height: auto;
            overflow: visible;
            position: absolute;
            top: 0px;
            right: 0px;
            display: flex;

            .layout-topbar {
                display: inline-block;
                width: fit-content;
                padding: 0px;
                padding-top: 0px;
                border: none;
                font-family: PoppinsMedium;

                .topbar-right {
                    border: 2px;
                    padding: 0px;
                    .topbar-menu {
                        .profile-item {
                            margin: 0px;
                            padding: 0px;
                            border: 2px;

                            .p-link {
                                color: $topbarmenuTextColor;
                                font-family: PoppinsMedium;
                                font-size: 16px;
                                font-weight: normal;
                                padding: 0px 10px 0px 3px;
                                margin-bottom: -5px;

                                i {
                                    margin-right: 0px;
                                    margin-top: -5px;
                                }
                            }

                            ul {

                                li {

                                    .p-link {
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .layout-logo-balance {
            display: flex;
            height: 100%;
            .balance-container {
                display: flex;
                height: 100%;
                padding-bottom: 2.1rem;
                padding-left: 1rem;
                width: 200;
                vertical-align: bottom;
                color: $topbarmenuTextColor;

                .balance {
                    align-self: flex-end;
                    padding-right: 8px;
                    margin-bottom: -2px;
                    p {
                        height: 100%;
                        font-size: 14px;
                        font-family: 'PoppinsRegular';
                    }
                }

                .vulacoin-currency-img {
                    margin-left: 2px;
                }

                .balance-quantity {
                    align-self: flex-end;
                    padding-left: 6px;
                    margin-left: 10px;
                    p {
                        font-size: 20px;
                        margin-bottom: -4px;
                        font-family: 'PoppinsMedium';
                    }
                }

                .rate-quantity {
                    align-self: flex-end;
                    padding-left: 6px;
                    margin-left: 10px;
                    p {
                        font-size: 20px;
                        margin-bottom: -4px;
                        font-family: 'PoppinsMedium';
                    }
                }
            }
        }

        .layout-menu-container-topbar {
            flex: 1;
            padding: 3rem 1rem 0rem 0rem;

            .p-menubar {
                background: none;
                border:none;
            }

            .p-menuitem-text {
                font-weight: normal;
            }
        }

        .layout-menu {
            margin: 0;
            padding: 0;
            list-style-type: none;
            color: $topbarmenuTextColor;
            font-family: PoppinsMedium;

            span.layout-menuitem-text {
                color: $topbarmenuTextColor;
                font-family: PoppinsMedium;
            }

            .active-route span.layout-menuitem-text {
                color: #f72fdc;
                font-family: PoppinsMedium;
            }

            .p-ripple {
                color: $topbarmenuTextColor;
            }

            a.ripple {
                color: $topbarmenuTextColor;
            }

            .menu-separator {
                margin: 1rem 0;
            }

            .layout-root-menuitem {
                > .layout-menuitem-root-text {
                    font-size: .857rem;
                    text-transform: uppercase;
                    font-weight: normal;
                    padding: .5rem 0 1rem 0;
                }

                a {
                    padding: 5px 10px 5px 3px;
                }

                > a,
                > .p-link {
                    display: none;
                }

                .layout-menu {

                    li {
                        float: left;

                        li {
                            float: none;
                            width: 100%;
                        }

                        button {
                            padding: 5px 10px 5px 5px;
                        }
                    }

                }

                .layout-menu-tooltip {
                    display: none;
                }

                .layout-menu-enter {
                    max-height: 0;
                    position: absolute;
                    background: white;
                }

                .layout-menu-enter-active {
                    overflow: hidden;
                    max-height: 1000px;
                    transition: max-height 1s ease-in-out;
                }

                .layout-menu-enter-done {
                    transform: none;
                    position: absolute;
                    background: white;
                }

                .layout-menu-exit {
                    max-height: 1000px;
                    position: absolute;
                    background: white;
                    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
                }

                .layout-menu-exit-active {
                    overflow: hidden;
                    max-height: 0;
                }
            }

            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;

                a,
                .p-link {
                    @include flex();
                    @include flex-align-center();
                    position: relative;
                    padding: .5rem;
                    font-size: 16px;
                    border-radius: $borderRadius;
                    outline: 0 none;
                    cursor: pointer;
                    transition: background-color $transitionDuration, box-shadow $transitionDuration;
                    width: 100%;

                    .layout-menuitem-icon {
                        margin-right: .5rem;
                    }

                    .layout-submenu-toggler {
                        font-size: 75%;
                        margin-left: auto;
                        transition: transform $transitionDuration;
                    }

                    &.rotated-icon {
                        .layout-menuitem-icon {
                            transform: rotate(90deg);
                        }
                    }

                    &.active-route {
                        font-weight: normal;
                        float: left;
                    }
                }

                li {
                    &.active-menuitem {
                        border-radius: $borderRadius;

                        > a,
                        > .p-link {
                            .layout-submenu-toggler {
                                transform: rotate(-180deg);
                            }
                        }

                        .layout-menu-enter-from {
                            max-height: 0;
                        }

                        .layout-menu-enter-to {
                            max-height: 1000px;
                        }

                        .layout-menu-enter-active {
                            overflow: hidden;
                            transition: max-height 1s ease-in-out;
                        }
                    }
                }

                ul {
                    overflow: hidden;
                    border-radius: $borderRadius;

                    li {
                        a, .p-link {
                            padding-left: 1.5rem;
                        }

                        li {
                            a, .p-link {
                                padding-left: 2.5rem;
                            }

                            li {
                                a, .p-link {
                                    padding-left: 3.5rem;
                                }

                                li {
                                    a, .p-link {
                                        padding-left: 4.5rem;
                                    }

                                    li {
                                        a, .p-link {
                                            padding-left: 5.5rem;
                                        }

                                        li {
                                            a, .p-link {
                                                padding-left: 6.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tabletBreakpoint) {
    
    .layout-topbar-menu {
        width: 22rem;
        height: 100%;
        background: white;
        position: fixed;
        top: 0px;
        left: 0px;
        box-shadow: $overlayTopMenuShadow;
        @include flex();
        @include flex-direction-column();
        user-select: none;
        transition: transform $animationDuration $animationTimingFunction;
    
        .logo {
            text-align: left;
            width: 100%;
            display: block;
            outline: 0 none;
            padding: 0rem;
            margin-top: 0rem;
            margin-bottom: 0px;
            margin-left: 10%;
            margin-right: 10%;
            transition: background-color $transitionDuration, box-shadow $transitionDuration;
        }
    
        .logo-img {
            width: 80%;
            /*margin-top: 5rem;*/
            /*margin-left: 10%;
            margin-right: 10%;*/
        }
    
        .app-name {
            font-size: 1.5rem;
            letter-spacing: .2px;
        }
    
        .layout-menu-container-topbar {
            flex: 1;
            padding: 0rem 4rem 1rem 1rem;
            width: auto;
            height: auto;
            overflow: visible;
            position: absolute;
            top: 0px;
            right: 0px;
            display: flex;
    
            .layout-topbar {
                display: inline-block;
                width: fit-content;
                padding: 0px;
                padding-top: 0px;
                border: none;
                font-family: PoppinsMedium;
    
                .topbar-right {
                    border: 2px;
                    padding: 0px;
                    .topbar-menu {
                        .profile-item {
                            margin: 0px;
                            padding: 0px;
                            border: 2px;
    
                            .p-link {
                                color: $topbarmenuTextColor;
                                font-family: PoppinsMedium;
                                font-size: 16px;
                                font-weight: normal;
                                padding: 0px 10px 0px 3px;
                                margin-bottom: -5px;
    
                                i {
                                    margin-right: 0px;
                                    margin-top: -5px;
                                }
                            }
    
                            ul {
    
                                li {
    
                                    .p-link {
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .profile-menuitem {
                display: inline-block;
                width: fit-content;
                padding: 0px;
                padding-top: 0px;
                border: none;
                font-family: PoppinsMedium;
    
                .topbar-right {
                    border: 2px;
                    padding: 0px;
                    .topbar-menu {
                        .profile-item {
                            margin: 0px;
                            padding: 0px;
                            border: 2px;
    
                            .p-link {
                                color: $topbarmenuTextColor;
                                font-family: PoppinsMedium;
                                font-size: 16px;
                                font-weight: normal;
                                padding: 0px 10px 0px 3px;
                                margin-bottom: -5px;
    
                                i {
                                    margin-right: 0px;
                                    margin-top: -5px;
                                }
                            }
    
                            ul {
    
                                li {
    
                                    .p-link {
                                        padding: 0.5rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .layout-logo-balance {
            width: 100%;
            margin-top: 5rem;
            flex: 0 1 auto;
            display: inline-block;
            .balance-container {
                justify-content: space-between;
                display: flex;
                //height: 100%;
                width: 80%;
                margin-left: 10%;
                vertical-align: bottom;
                color: $topbarmenuTextColor;
                
                .balance {
                    align-self: flex-end;
                    margin-bottom: -2px;
                    p {
                        height: 100%;
                        font-size: 14px;
                        font-family: 'PoppinsRegular';
                    }
                }
    
                .vulacoin-currency-img {
                    margin-left: 2px;
                }
    
                .balance-quantity {
                    align-self: flex-end;
                    margin-left: 7px;
                    p {
                        font-size: 20px;
                        margin-bottom: -4px;
                        font-family: 'PoppinsMedium';
                    }
                }

                .rate-quantity {
                    align-self: flex-end;
                    margin-right: 10px;
                    p {
                        font-size: 20px;
                        margin-bottom: -4px;
                        font-family: 'PoppinsMedium';
                    }
                }
            }
        }
    
        .scrolling-menu {
            flex: 1 1 auto;
            overflow: scroll;
            margin-bottom: 3rem;
            margin-top: 1rem;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .scrolling-menu::-webkit-scrollbar {
            display: none;
        }
        
        .layout-menu-container-topbar {
            flex: 1;
            padding: 0rem 1rem 0rem 2rem;
            position: relative;
    
            .p-menubar {
                background: none;
                border:none;
            }
    
            .p-menuitem-text {
                font-weight: normal;
            }
        }
    
        .layout-menu:hover {
            background: none;
        }
        
        .layout-menu {
            margin: 0;
            padding: 0;
            list-style-type: none;
            color: $topbarmenuTextColor;
            font-family: 'PoppinsMedium';
    
            li a:hover {
                background: none;
            }
            
            .menuitem {
                float: none;
            }
            
            span.layout-menuitem-text {
                color: $topbarmenuTextColor;
                font-family: PoppinsMedium;
            }
    
            .active-route span.layout-menuitem-text {
                color: #f72fdc;
                font-family: PoppinsMedium;
            }
    
            .p-ripple {
                color: $topbarmenuTextColor;
            }
            
            a.ripple {
                color: $topbarmenuTextColor;
            }
    
            .menu-separator {
                margin: 1rem 0;
            }
    
            .layout-root-menuitem {
                > .layout-menuitem-root-text {
                    font-size: .857rem;
                    text-transform: uppercase;
                    font-weight: normal;
                    padding: .5rem 0 1rem 0;
                }
    
                a {
                    padding: 5px 10px 5px 3px;
                }
    
                > a,
                > .p-link {
                    display: none;
                }
    
                .layout-menu {
    
                    .active-menuitem {
                        a span {
                            padding-left: 1rem;
                        }
                    }
                    
                    li {
                        li {
                            float: none;
                            width: 100%;
                        }
    
                        button {
                            padding: 5px 10px 5px 3px;
                        }
                    }
    
                }
    
                .layout-menu-tooltip {
                    display: none;
                }
    
                .layout-menu-enter {
                    max-height: 0;
                    position: absolute;
                    background: white;
                }
    
                .layout-menu-enter-active {
                    overflow: hidden;
                    max-height: 1000px;
                    transition: max-height 1s ease-in-out;
                }
    
                .layout-menu-enter-done {
                    transform: none;
                    position: absolute;
                    background: white;
                }
    
                .layout-menu-exit {
                    max-height: 1000px;
                    position: absolute;
                    background: white;
                }
    
                .layout-menu-exit-active {
                    overflow: hidden;
                    max-height: 0;
                    transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
                }
            }
    
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
    
                a,
                .p-link {
                    @include flex();
                    @include flex-align-center();
                    position: relative;
                    padding: .5rem;
                    font-size: 16px;
                    border-radius: $borderRadius;
                    outline: 0 none;
                    cursor: pointer;
                    transition: background-color $transitionDuration, box-shadow $transitionDuration;
                    width: 100%;
    
                    .layout-menuitem-icon {
                        margin-right: .5rem;
                    }
    
                    .layout-submenu-toggler {
                        font-size: 75%;
                        margin-left: auto;
                        transition: transform $transitionDuration;
                    }
    
                    &.rotated-icon {
                        .layout-menuitem-icon {
                            transform: rotate(90deg);
                        }
                    }
    
                    &.active-route {
                        font-weight: normal;
                        float: left;
                    }
                }
    
                li {
                    &.active-menuitem {
                        border-radius: $borderRadius;
    
                        > a,
                        > .p-link {
                            .layout-submenu-toggler {
                                transform: rotate(-180deg);
                            }
                        }
    
                        .layout-menu-enter-from {
                            max-height: 0;
                        }
    
                        .layout-menu-enter-to {
                            max-height: 1000px;
                        }
    
                        .layout-menu-enter-active {
                            overflow: hidden;
                            transition: max-height 1s ease-in-out;
                        }
                    }
                }
    
                ul {
                    overflow: hidden;
                    border-radius: $borderRadius;
    
                    li {
                        a, .p-link {
                            padding-left: 1.5rem;
                        }
    
                        li {
                            a, .p-link {
                                padding-left: 2.5rem;
                            }
    
                            li {
                                a, .p-link {
                                    padding-left: 3.5rem;
                                }
    
                                li {
                                    a, .p-link {
                                        padding-left: 4.5rem;
                                    }
    
                                    li {
                                        a, .p-link {
                                            padding-left: 5.5rem;
                                        }
    
                                        li {
                                            a, .p-link {
                                                padding-left: 6.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $phoneBreakpoint) {
    
    .layout-topbar-menu {
        width: 100%;
        max-width: 22rem;
        height: 100%;
        background: white;
        position: fixed;
        top: 0px;
        left: 0px;
        box-shadow: $overlayTopMenuShadow;
        @include flex();
        @include flex-direction-column();
        user-select: none;
        transition: transform $animationDuration $animationTimingFunction;
    }
}