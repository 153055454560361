@import './pages/Payments/Payments.scss';
@import './components/Header/Header.scss';
@import './components/VCL/VCL.scss';

.client-app {
  .font-face-poppins-m {
    font-family: PoppinsMedium, sans-serif;
  }

  .client-container {
    margin: auto;
    // overflow-y: scroll;
    // height: 100vh;
    // overflow-x: hidden;
    max-width: 778px;
    padding-bottom: 85px;

    .card {
      background: white;
      border: 1px solid #e4e6eb;
      border-radius: 12px;
      box-shadow: 0 4px 4px 0 #3440541a;
      margin: 8px 10px;
      padding: 16px;
    }

    .card.full-back {
      background: none;
      border: 0;
      border-radius: 12px 12px 0 0;
      box-shadow: none;
      margin: 8px 0;
      padding: 0;
      width: 100%;
    }
  }

  .client-header-container,
  .client-header {
    margin-left: auto;
    margin-right: auto;
    max-width: 775px;
    text-align: center;
    width: 100%;

    &.dashboard {
      background: #fff;
      border-radius: 0 0 22px 22px;
      box-shadow: 0 0 10px rgb(0 0 0 / 15%);
      left: 50%;
      margin-left: -389px;
      margin-right: auto;
      position: fixed;
      top: 0;
      width: 778px;
      z-index: 1000;

      .dashboard-nav {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        a {
          margin-bottom: auto;
          margin-top: auto;
          text-align: left;
        }

        &-top-text {
          color: #1f1f39;
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 32px;
          text-align: center;
        }

        &-merchant-logo {
          cursor: pointer;
          justify-self: end;

          img {
            position: absolute;
            right: 20px;
            top: 20px;
          }
        }
      }
    }

    h2 {
      margin: 1rem 0;
    }

    .search-input {
      padding-bottom: 10px;
      padding-top: 10px;
      width: 100%;

      span {
        width: 90%;

        input {
          border-radius: 10px;
          padding-right: 60px;
        }
      }

      img {
        background: linear-gradient(145deg, #2195fd 9%, #ff00f7 100%);
        border-radius: 100%;
        padding: 10px;
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }

    &.login {
      img {
        height: 85px;
        margin-bottom: 53px;
        margin-top: 100px;
        object-fit: contain;
      }
    }
  }

  @media (width <= 768px) {
    .client-header-container {
      left: 0;
      margin-left: auto;
      width: 100%;

      &.dashboard {
        left: 0%;
        margin-left: 0;
        margin-right: 0;
        position: fixed;
        top: 0;
        width: 100%;
      }
    }
  }

  .payment-button-container,
  .send-button-container,
  .merchants-button-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    // margin: 8px 10px;
    padding-bottom: 85px;
    width: 100%;

    button {
      border-radius: 8px;
      border-width: 0;
      font-size: 16px;
      height: 50px;
      margin-left: 5px;
      margin-right: 5px;
      max-width: 160px;
      width: 160px;

      &.topup {
        background: #3d5cff;
        color: #f3f3f3;
      }

      &.cancel,
      &:hover {
        background: none;
        border: 1px solid #3d5cff;
        border-radius: 8px;
        color: #3d5cff;
      }
    }

    .right {
      justify-self: flex-end;
    }

    .left {
      justify-self: flex-start;
    }
  }

  .own-amount-input {
    width: 100%;

    .p-col-12 {
      padding: 0;
    }

    small {
      bottom: 1.75rem;
      float: right;
      margin-right: 15px;
      position: relative;
    }

    input {
      border-bottom-right-radius: 10px !important;
      border-top-right-radius: 10px !important;
      margin-right: 10px;
    }
  }

  .sell-own-amount-input {
    width: 100%;

    .p-col-12 {
      padding: 0;
    }

    small {
      bottom: 1.75rem;
      float: right;
      margin-right: 15px;
      position: relative;
    }

    input {
      border-bottom-right-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
  }

  .conversion-amount {
    padding-right: 10px;
    text-align: right;
  }

  .client-balance-inputs,
  .client-login-body {
    font-size: medium;
    padding-top: 15px;

    .phone-dropdown {
      border: 0;
      height: 2rem;
    }

    .flag {
      margin-left: 0.5rem;
      margin-top: 1rem;
    }

    .p-inputgroup-addon.vula {
      height: 50px;
      width: 100px;

      b {
        color: #1f1f39;
        font-family: PoppinsRegular, sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
      }
    }

    .p-inputtext.p-component {
      background: white;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      color: #1f1f39;
      font-family: PoppinsRegular, sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 32px;
      margin-right: 10px;
      outline: white;
      padding-left: 17px;
    }

    .wn-amount-input > div {
      border-radius: 10px;
      height: 50px;
      margin-left: 10px;
      width: 6.5rem;

      .p-dropdown-label.p-inputtext {
        color: #1f1f39;
        font-family: PoppinsRegular, sans-serif;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 32px;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        text-align: center;
      }
    }
  }

  .p-error {
    padding-left: 15px;
  }

  .d-disable {
    color: #615f5fb5;
    filter: brightness(0.92);
  }

  .amount-input-img {
    height: 14px;
  }

  .select-amount-img {
    height: 22px;
    margin-left: 5px;
  }

  .client-transactions-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
    max-width: 810px;

    .infinite-scroll-component__outerdiv {
      width: 100%;

      .infinite-scroll-component {
        overflow: hidden scroll !important;
        // height: 100%;
        padding-top: 10px;

        b {
          font-family: PoppinsRegular, sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .transaction-history-text {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 0;
    padding-left: 18px;
    padding-top: 1rem;
    text-align: left;
    width: 100%;
  }

  .client-payment-title-bar {
    border-bottom: 1px solid #c9ccd1;
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;

    .p-col-1,
    .p-col-9 {
      padding-bottom: 0;
      padding-top: 1rem;
    }
  }

  .payment-option-container {
    .card {
      border-radius: 10px;
      margin: 0 0 1rem;
    }

    button {
      background: #3d5cff;
      border: 1px solid #3d5cff;
    }
  }

  .product-image {
    height: 60px;
    width: 60px;
  }

  .payment-option-container-top {
    display: grid;
    gap: 2px;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-template-areas: 'payment-option-product-image-container payment-option-container-info';
    grid-template-rows: 1fr;
    width: 100%;

    &.add-image {
      grid-template-columns: 0.4fr 1.6fr;
    }

    &.no-image {
      grid-template-columns: 0 2fr;
    }
  }

  .payment-option-product-image-container {
    grid-area: payment-option-product-image-container;
    justify-self: center;
    width: 60px;
  }

  .payment-option-container-info {
    display: grid;
    gap: 0;
    grid-area: payment-option-container-info;
    grid-auto-flow: row;
    grid-template-areas:
      'product-name'
      'vulacoin-balance'
      'cell-number';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100%;
  }

  .product-name {
    font-family: PoppinsBold, sans-serif;
    grid-area: product-name;
  }

  .vulacoin-balance {
    color: #b8b8d2;
    grid-area: vulacoin-balance;
  }

  .cell-number {
    color: #b8b8d2;
    grid-area: cell-number;
  }

  .peach-payment-container {
    margin-top: 1rem;
    text-align: left;

    .wpwl-form {
      background-color: transparent !important;
      border: 0;
      font-family: Nunito, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
      margin: 0;
      margin-top: 1rem;

      button {
        background-color: #1e98fd;
        border-color: #1e98fd;
      }
    }

    .wpwl-form-has-inputs {
      background-color: white !important;

      button {
        margin: 0;
      }
    }

    form {
      background: white;
      border: 1px solid #e4e6eb;
      border-radius: 8px;
      box-shadow: 0 0 10px rgb(0 0 0 / 15%);
      margin: 8px 16px;
    }
  }

  .balance-payment-container {
    font-size: 18px;
    margin: 1rem;

    .right {
      text-align: right;
    }
  }

  .payment-complete-container {
    background: none;
    height: 160px;
    margin-top: 122px;
    padding: 0 5%;
    text-align: center;
    width: 100%;

    &.loading {
      margin: 122px auto auto;
      width: 90%;
    }
  }

  .payment-loading-label {
    margin-left: 20px;
  }

  .payment-complete-label {
    font-size: 34px;
    font-weight: 600;
    height: 32px;
    line-height: 41px;
    margin: 121px 0 0;
    text-align: center;

    &.loading {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.2;
      margin: auto;
    }
  }

  .payment-complete-sub-label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 13px;
    text-align: center;
  }

  .thumb-border {
    border-radius: 50%;
    position: absolute;

    &.outer-circle {
      border: 1px solid rgb(74 151 81 / 100%);
      height: 156px;
      left: calc(50% - 78px);
      top: 202px;
      width: 156px;
    }

    &.middle-circle {
      border: 1px solid rgb(74 151 81 / 100%);
      height: 140px;
      left: calc(50% - 70px);
      top: 210px;
      width: 140px;
    }

    &.inner-circle {
      border: 1px solid rgb(74 151 81 / 100%);
      height: 118px;
      left: calc(50% - 59px);
      top: 221px;
      width: 118px;
    }

    &.background {
      background: linear-gradient(131.53deg, #33c240 17.64%, #11761a 83.23%);
      height: 100px;
      left: calc(50% - 50px);
      top: 230px;
      width: 100px;
    }

    &.icon {
      border-radius: 0;
      height: 40px;
      left: calc(50% - 20px);
      top: 260px;
      width: 40px;
      z-index: 1;

      &.failed {
        transform: scaleY(-1);
      }
    }
  }

  .payment-complete-container {
    button {
      border-radius: 10px;
      height: 48px;
      position: absolute;
      top: 390px;
      width: 158px;

      &.form-tribe-btn {
        background: #3d5cff;
        left: calc(50% - 9px - 158px);
      }

      &.done-btn {
        background: #000;
        border-color: #000;
        color: #fff;
        left: calc(50% + 9px);
      }
    }
  }

  .p-button:hover,
  .p-button:focus,
  .p-button:focus-within {
    box-shadow: none !important;
  }

  .p-inputgroup-addon {
    background: white;
    border-radius: 10px !important;
    border-right: 1px solid #ced4da;
    left: 10px;
    position: relative;
    width: 6.5rem;
    z-index: 100;

    b {
      color: #1f1f39;
      font-family: PoppinsRegular, sans-serif;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }
  }

  .p-inputnumber {
    width: 100%;
  }

  .p-inputtext {
    color: #1f1f39;
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    letter-spacing: 0;
    line-height: 32px;
    padding-left: 15px;
    // margin-right: 10px;
    width: 100%;
  }

  .p-inputtext:enabled:focus {
    border-color: #ced4da;
    box-shadow: none;
  }

  .p-inputnumber-input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    z-index: 10;
  }

  .p-dropdown-label {
    align-self: center;
    padding: 0;
    text-align: center;
  }

  .p-toast-message-icon {
    align-self: center;
  }

  .skeleton-f-right {
    float: right;
  }

  .d-none {
    display: none;
  }

  .d-disabled {
    //pointer-events: none;
    background: #f2f2f2;
    cursor: not-allowed;
  }

  .client-pay-methods {
    margin: auto;
    // padding-top: 100px;
    max-width: 845px;
  }

  h6 {
    color: #000;
    font-family: PoppinsMedium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 32px;
    margin-bottom: 0.2rem;
    margin-left: 10px;
  }

  p {
    font-family: PoppinsRegular, sans-serif;
    font-size: 12px;
    font-weight: 400;
    // color: #1f1f39;
    letter-spacing: 0;
    // text-align: right;
    line-height: 32px;
  }

  .p-dialog-header {
    color: #000;
    font-family: PoppinsMedium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
  }

  .p-confirm-dialog {
    color: #000;
    font-family: PoppinsMedium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
    margin-left: 5px;

    &-message {
      color: #000;
      font-family: PoppinsMedium, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.2px;
      margin-left: 5px;
    }

    &-reject .p-button {
      background: none;
      border: 1px solid #3d5cff;
      border-radius: 8px;
      color: #3d5cff;

      &:enabled:hover {
        background: #3d5cff;
        color: white;
      }
    }

    &-accept .p-button {
      background: #3d5cff;
      border-radius: 8px;
      color: #f3f3f3;

      &:enabled:hover {
        opacity: 0.8;
      }
    }
  }

  .p-autocomplete-list {
    :first-child.p-autocomplete-list-item {
      padding-top: 0;
    }

    .p-autocomplete-list-item {
      border-bottom: 1px solid lightgrey;
      margin: 0 10px;
      padding: 0.5em 0;
    }

    :last-child.p-autocomplete-list-item {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  button.p-button.foo {
    &:focus,
    &:hover {
      box-shadow: none;
      color: white;
    }
  }
}

.user-recap-button {
  background: none;
  border: 0;
  bottom: 4px;
  color: #000;
  height: 38px;
  position: relative;
  width: 2.6rem;

  .pi {
    font-size: 20px;
    font-weight: bold;
  }
}

.p-inputtext.p-component.cell-input-text:focus {
  background: #fff !important;
  border-color: #f6f6f6 !important;
}

.p-inputtext.p-component.cell-input-text:hover {
  border-color: #f6f6f6 !important;
}

.client-balance {
  height: 102px;
  margin: 10px auto 20px;
  vertical-align: middle;

  &-container {
    margin-left: 10px;
    margin-right: 10px;

    &.no-buttons {
      padding-top: 100px;
    }

    &.include-buttons {
      padding-top: 90px;
    }

    &.vula-connect-padding {
      padding-top: 0;
    }
  }

  &-button-container {
    display: grid;
    grid-template-areas:
      'topup send'
      'products products';
    grid-template-columns: 1fr 1fr;
    margin: 8px 10px;

    a.payment-link {
      grid-area: topup;
    }

    a.send-link {
      grid-area: send;
    }

    a.sell-link {
      display: flex;
      grid-area: products;
      justify-content: center;
    }

    button {
      border-radius: 8px;
      border-width: 0;
      color: #fff;
      font-size: 16px;
      height: 50px;
      margin-left: 5px;
      margin-right: 5px;
      max-width: 160px;
      width: 160px;

      &.topup {
        background: #3d5cff;
      }

      &.send {
        background: #ff00f7;
      }

      &.send:hover {
        background: #ff00f7;
        opacity: 0.8;
      }

      &.sell {
        background: #a113ef;
        margin: 10px 0;
        max-width: 330px;
        width: 330px;
      }

      &.sell:hover {
        background: #a113ef;
        opacity: 0.8;
      }
    }

    .right {
      justify-self: flex-end;
    }

    .left {
      justify-self: flex-start;
    }
  }

  // real one
  &.border {
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 8px 12px -4px rgba(184, 184, 210, 0.2);
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);
    max-width: 810px;
  }

  &-text {
    color: #1f1f39;
    font-size: 14px !important;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
  }

  .balance-label {
    color: #b8b8d2;
    font-family: PoppinsRegular, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    margin-top: 2px;
    text-align: center;
    width: 100%;
  }

  .vulacoin-currency {
    // display: flex;
    // justify-content: center;
    // height: 50px;
    // margin-top: 19px;
    text-align: center;

    .bottom-left {
      align-self: end;
    }

    &-amount {
      color: #1f1f39;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px !important;
      margin-bottom: 0;
      margin-top: 20px;
      padding-right: 5px;
      text-align: center;
    }

    img {
      padding-bottom: 5px;
    }
  }
}
