.client-app {
  .client-header {
    align-items: center;
    align-self: stretch;
    background: var(--base-white, #fff);
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    border-radius: 0 0 14px 14px;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 6%), 0 1px 21px 0 rgb(16 24 40 / 10%);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    min-height: 74px;
    padding: 17px 8px 17px 16px;

    .header-left {
      align-items: center;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      padding: 8px;
    }

    .header-center {
      color: var(--gray-900, #101828);
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      height: 50px;
      justify-content: center;
      line-height: 30px; /* 150% */
      text-align: center;

      img {
        max-height: 50px;
      }
    }

    .header-right {
      align-items: center;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      min-height: 3rem;
      min-width: 3rem;
      padding: 8px;
    }
  }

}
