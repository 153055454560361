.client-app {
  .header-top-text {
    color: #1f1f39;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 32px;
    margin: auto;
    margin-top: 0;
    text-align: center;
  }
}
