@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('PoppinsSemiBold'),
    url('./Fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'),
    url('./Fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: local('PoppinsMedium'),
    url('./Fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: local('PoppinsBold'),
    url('./Fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VadodaraSemiBold';
  src: local('VadodaraSemiBold'),
    url('./Fonts/Vadodara/HindVadodara-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'VadodaraRegular';
  src: local('VadodaraRegular'),
    url('./Fonts/Vadodara/HindVadodara-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'VadodaraMedium';
  src: local('VadodaraMedium'),
    url('./Fonts/Vadodara/HindVadodara-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'VadodaraBold';
  src: local('VadodaraBold'),
    url('./Fonts/Vadodara/HindVadodara-Bold.ttf') format('truetype');
}
