.otp-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .input-box.otp-code-input {
    color: var(--gray-400, #98a2b3);
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 70px;
    line-height: 24px;
    text-align: center;
  }

  .input-box.otp-code-input.non-empty {
    color: var(--base-black, #222);
  }

  .default-tnc-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .login-otp-resend-code.disabled {
    background: none;

    .vulacoin-text {
      color: var(--primary-900, #062043);
    }
  }
}
