.layout-topbar-menu {
    background: $topbarmenuBgColor;
    border-right: $topbarmenuBorder;
    background-image: linear-gradient(180deg,$topbarmenuBgColor 10%,$topbarmenuBgColorAlt 100%);

    .logo {
        &:focus {
            box-shadow: $menuitemFocusShadow;
        }
    }

    .app-name {
        color: $appNameColor;
    }

    .layout-menu {
        .menu-separator {
            border-top: $menuSeparatorBorder;
        }

        li {
            &.active-menuitem {
                > a,
                > .p-link {
                    color: $menuitemTextActiveColor;
                }
            }

            a,
            .p-link {
                color: $menuitemTextColor;

                &:hover {
                    background: $menuitemHoverBg;
                }

                &:focus {
                    box-shadow: $menuitemFocusShadow;
                }
            }
        }

        .layout-root-menuitem {
            .layout-menuitem-root-text {
                color: $menuitemRootTextColor;
            }

            &.active-menuitem {
                background: $menuitemActiveBg;
            }

            > ul {
                > li.active-menuitem {
                    background: $menuitemActiveBg;
                }
            }
        }
    }
}

@media screen and (min-width: $tabletBreakpoint + 1) {
    &.layout-slim {
        .layout-topbar-menu {
            .layout-menu {
                .layout-root-menuitem {
                    > ul {
                        background: $topbarmenuBgColor;
                        background-image: linear-gradient(180deg,$topbarmenuBgColor 10%,$topbarmenuBgColorAlt 100%);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tabletBreakpoint + 1) {
    .layout-topbar-menu {
        .layout-menu {

            .layout-root-menuitem {
                &.active-menuitem {
                    background: transparent;
                }
            }
    
            li {
                &.active-menuitem {
                    > a,
                    > .p-link {
                        color: $menuitemTextActiveColor;
                    }
                }
    
                a,
                .p-link {
                    color: $menuitemTextColor;
    
                    &:hover {
                        background: none;
                    }
    
                    &:focus {
                        box-shadow: $menuitemFocusShadow;
                    }
                }
            }
        }
    }
}
