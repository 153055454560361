
.vulacoin-text {
  margin: 0;
  text-decoration: none;
}

.vulacoin-text.heading-1 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}

.vulacoin-text.heading-2 {
  color: #222;
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.vulacoin-text.heading-3 {
  color: #222;
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
}

.vulacoin-text.heading-4 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}

.vulacoin-text.heading-5 {
  color: #222;
  font-family: PoppinsMedium, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.vulacoin-text.sub-heading-1 {
  color: #344054;
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.vulacoin-text.sub-heading-2 {
  color: #222;
  font-family: PoppinsRegular, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.vulacoin-text.sub-heading-3 {
  color: var(--gray-700, #222);
  font-family: PoppinsRegular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.vulacoin-text.button-1 {
  color: var(--gray-600, #475467);
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.vulacoin-text.regular-12 {
  color: #222;
  font-family: PoppinsRegular, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.vulacoin-text.regular-14 {
  color: #667085;
  color: var(--gray-400, #98a2b3);
  font-family: PoppinsRegular, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.vulacoin-text.regular-16 {
  color: #222;
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.vulacoin-text.regular-18 {
  color: var(--typography-text-primary, #222);
  font-family: PoppinsRegular, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.vulacoin-text.regular-20 {
  color: #222;
  font-family: PoppinsRegular, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

.vulacoin-text.bold-12 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
}

.vulacoin-text.bold-20 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}

.vulacoin-text.bold-24 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
}

.vulacoin-text.medium-14 {
  color: #667085;
  color: var(--gray-400, #98a2b3);
  font-family: PoppinsMedium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.vulacoin-text.medium-16 {
  color: #222;
  font-family: PoppinsMedium, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.vulacoin-text.semibold-14 {
  color: #667085;
  color: var(--gray-400, #98a2b3);
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.vulacoin-text.semibold-16 {
  color: #222;
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.vulacoin-text.semibold-18 {
  color: #222;
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
}

.vulacoin-text.semibold-20 {
  color: var(--typography-text-primary, #222);
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.vulacoin-text.semibold-24 {
  color: #222;
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  text-align: center;
}

.vulacoin-text.product-header-1 {
  color: #222;
  font-family: PoppinsBold, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  text-align: center; 
}

.vulacoin-text.product-header-2 {
  color: #222;
  font-family: PoppinsMedium, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px;
  text-align: center; 
}


.vulacoin-text.base-blue {
  color: #3e5cff;
}

.vulacoin-text.gray-800 {
  color: var(--gray-800, #1d2939);
}

.vulacoin-text.gray-500 {
  color: var(--gray-500, #667085);
}

.vulacoin-text.base-white {
  color: var(--base-white, #fff);
}

.vulacoin-text.gray-700 {
  color: var(--gray-700, #344054);
}

.vulacoin-text.base-black {
  color: var(--base-black, #222);
}

.vulacoin-text.base-red {
  color: var(--error-600, #d92d20);
}

.vulacoin-text.grey-400 {
  color: var(--gray-400, #98a2b3);
}

.vulacoin-text.primary-900 {
  color: var(--primary-900, #062043);
}

.vulacoin-text.text-disabled {
  color: var(--typography-text-disabled, #667085);
}

