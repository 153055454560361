.buying-title,
.voucher-title,
.topup-title {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin: 24px 12px 0;
}

.buying-sub-title,
.voucher-sub-title,
.topup-sub-title {
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin: 4px 12px 12px;
}

.buying-sub-title {
  margin-top: 24px;
}

.buying-body,
.topup-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px 12px 0;
  padding-bottom: 75px;
}

.product-card {
  align-items: center;
  align-self: stretch;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 20px 12px 0;
  padding: 12px 16px;

  .product-icon {
    border-radius: 33px;
    height: 40px;
    min-width: 40px;

    img {
      max-width: 40px;
    }
  }
  
  .product-details {
    flex: 1;

    .product-name {
      color: #000;

      /* Text md/Semibold */
      font-family: PoppinsMedium, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .user-number {
      color: var(--gray-400, #98a2b3);

      /* Text xs/Regular */
      font-family: PoppinsRegular, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }

  .product-cost {
    .product-fiat {
      color: var(--base-black, #000);
      
      /* Text md/Regular */
      font-family: PoppinsRegular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-align: right;
    }

    .product-vc {
      align-items: center;
      color: var(--gray-400, #98a2b3);
      display: flex;
      
      /* Text xs/Regular */
      font-family: PoppinsRegular, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      gap: 6px;
      line-height: 18px; /* 150% */
      text-align: right;

      .vc-icon {
        height: 12px;
        width: 12px;
      }
    }
  }
}

.payment-method-card ~ .payment-method-card,
.payment-method-qr ~ .payment-method-card,
.payment-method-card ~ .payment-method-qr {
  margin-top: 12px;
}

.payment-method-card {
  align-items: center;
  background: var(--base-white, #fff);
  border: 1px solid var(--gray-200, #eaecf0);
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  min-height: 64px;
  padding: 12px 16px;

  &.disabled {
    background-color: #eaecf0;
  }


  &.selected {
    background: var(--base-white, #fff);
    border: 1px solid var(--primary-600, #01d9f3);
    border-radius: 8px;

    /* Shadow/md */
    box-shadow: 0 2px 4px -2px rgb(16 24 40 / 6%), 0 4px 8px -2px rgb(16 24 40 / 10%);
  }

  >* ~ * {
    margin-left: 16px;
  }

  .payment-method-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-weight: bold;

    .topup-title {
      color: var(--gray-700, #344054);
      font-family: PoppinsMedium, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .payment-method-sub-title {
      color: var(--gray-500, #667085);
      font-family: PoppinsRegular, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

.amount-select-card {
  align-items: flex-start;
  align-self: stretch;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 12px 12px 0;
  padding: 24px 16px;

  .amount-min-label {
    color: var(--gray-700, #344054);

    /* Text sm/Regular */
    font-family: PoppinsRegular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-left: auto;
    text-align: right;
  }

  .amount-input-container {
    background: var(--base-white, #fff);
    border: 1px solid var(--gray-300, #d0d5dd);
    border-radius: 8px;

    /* Shadow/xs */
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
    display: flex;
    margin: 6px 0;
    width: 100%;

    .amount-currency-label {
      color: var(--gray-900, #101828);

      /* Text md/Regular */
      font-family: PoppinsRegular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      min-width: 47px;
      padding: 10px 14px;
      text-align: center;
    }

    .amount-input {
      align-items: center;
      color: var(--gray-700, #344054);
      display: flex;
      flex: 1;

      /* Text md/Regular */
      font-family: PoppinsRegular, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-right: 5px;

      .p-inputtext {
        border: 0;
        border-left: 1px solid var(--gray-300, #d0d5dd);
        padding: 10px 14px;
      }
    }
  }

  .amount-vc-label {
    color: var(--gray-700, #344054);

    /* Text xs/Regular */
    font-family: PoppinsRegular, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-left: auto;
    text-align: right;

    .amount-vc-content {
      color: var(--primary-900, #42307d);

      /* Text xs/Medium */
      font-family: PoppinsRegular, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }
  }
}

.failed-button-container,
.complete-button-container,
.voucher-button-container,
.amount-select-button-container {
  display: flex;
  gap: 16px;
  margin: 24px 28px 0;

  .failed-cancel-button,
  .complete-circles-button,
  .voucher-cancel,
  .amount-select-cancel {
    align-items: center;
    background: none;
    border: 1px solid var(--gray-800, #1d2939);
    border-radius: 8px;

    /* Shadow/xs */
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    margin-right: auto;
    max-width: 50%;
    padding: 12px 18px;

    .p-button-label {
      color: var(--gray-800, #1d2939);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    &:focus-visible,
    &:hover {
      background: none;
      border: 1px solid var(--gray-800, #1d2939);
      color: var(--gray-800, #1d2939);
      outline: none;
    }

    .p-button-label:focus-visible {
      outline: none;
    }
  }

  .failed-restart-button,
  .complete-again-button,
  .voucher-proceed,
  .amount-select-proceed {
    align-items: center;
    border: 1px solid;
    border-radius: 8px;

    /* Shadow/xs */
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    margin-left: auto;
    max-width: 50%;
    padding: 12px 18px;

    .p-button-label {
      color: var(--base-white, #fff);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    &:disabled {
      background: var(--gray-300, #d0d5dd);
      border: 1px solid var(--gray-300, #d0d5dd);
    }
  }

  .failed-restart-button {
    background: var(--gray-800, #1d2939);
    border: 1px solid var(--gray-800, #1d2939);
  }
}

.payment-status-title {
  color: var(--gray-900, #101828);
  font-family: PoppinsRegular, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin-top: 70.5px;
  text-align: center;
}

.payment-status-sub-title {
  color: var(--gray-700, #344054);
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: center;
}

.payment-status-content {
  display: flex;
  flex-direction: column;
}

.payment-success-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 75px;
}

.payment-success-content,
.payment-failed-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 75px;

  .payment-centered-content {
    display: flex;
    flex-direction: column;
    margin: auto 0;
  }

  .payment-success-icon,
  .payment-failed-icon {
    align-items: center;
    border-radius: 100px;
    display: flex;
    height: 69px;
    justify-content: center;
    margin: 0 auto;
    width: 69px;
  }

  .payment-failed-icon {
    background: var(--error-500, #f04438);
  }

  .payment-success-title,
  .payment-failed-title {
    color: var(--gray-900, #101828);

    /* Display xs/Semibold */
    font-family: PoppinsMedium, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    margin-top: 12px;
    text-align: center;
  }

  .payment-failed-sub-title {
    color: var(--gray-700, #344054);

    /* Text md/Regular */
    font-family: PoppinsRegular, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 12px;
    text-align: center;
  }

  .failed-payment-notification {
    flex-flow: row-reverse;
    margin-top: auto;
    top: 0;

    .message-container {
      background: var(--error-50, #fef3f2) !important;

      .message-text {
        color: var(--error-600, #d92d20) !important;
      }
    }

    .navigation-btn-container {
      background: var(--error-50, #fef3f2) !important;

      .navigation-btn {
        background: var(--error-500, #f04438) !important;
        color: var(--base-white, #fff) !important;
      }
    }
  }
}

.payment-success-content {
  width: 100%;
}

.payment-step-container {
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  margin: 48px auto 0;

  .payment-step {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 16px;

    &.faded {
      opacity: 0.3;
    }
  }

  .payment-step-icon {
    align-items: center;
    border-radius: 20px;
    display: flex;
    flex-shrink: 0;
    height: 40px;
    justify-content: center;
    padding: 8px;
    width: 40px;

    &.disabled {
      background: var(--gray-300, #d0d5dd);
    }
  }

  .payment-step-text {
    color: var(--gray-700, #344054);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .payment-step-connector {
    border-radius: 2px;
    height: 24px;
    margin: 0 auto 0 20px;
    width: 2px;

    &.disabled {
      background: var(--gray-300, #d0d5dd);
    }
  }
}

.payment-status-warning {
  align-items: center;
  align-self: stretch;
  background: var(--vcblue-25, #e6e9fc);
  border-radius: 16px;
  display: flex;
  flex: 1 0 0;
  font-family: PoppinsRegular, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 94px 24px 24px;
  padding: 16px;
}

.payment-status-warning-note {
  align-items: center;
  background: var(--vcblue-600, #3e5cff);
  border-radius: 16px;
  color: white;
  font-family: PoppinsRegular, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-right: 8px;
  padding: 2px 8px;
}

.voucher-content {
  padding: 16px 28px;
}

.voucher-input {
  background: var(--base-white, #fff);
  border: 1px solid var(--gray-300, #d0d5dd);
  border-radius: 8px;

  /* Shadow/xs */
  box-shadow: 0 1px 2px 0 rgb(16 24 40 / 5%);

  /* Text md/Regular */
  font-family: PoppinsRegular, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 48px;
}

.payment-notification {
  &.small-nav-notification {
    margin-top: 11px;
    position: unset;
    right: unset;
    top: unset;
    transform: unset;
  }
}

.complete-success-card {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 17px auto auto;

  .complete-success-icon {
    align-items: center;
    border-radius: 30px;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    justify-content: center;
    padding: 10.145px 12.319px 14.493px;
    width: 50px;
  }

  .complete-success-card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .complete-success-title-text {
    color: var(--gray-900, #101828);

    /* Text xl/Semibold */
    font-family: PoppinsMedium, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }

  .complete-success-sub-title-text {
    color: var(--gray-700, #344054);

    /* Text sm/Regular */
    font-family: PoppinsRegular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
