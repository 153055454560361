.client-app {
  .client-footer {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
    margin-top: auto;
    text-align: center;

    &-logo {
      height: 40px;
      text-align: center;
      width: 37.2px;
    }

    &-link-container {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &-link-text {
      text-decoration: underline;
    }
  }

  .security-footer-text {
    color: #858597;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }

  .footer-text {
    background: inherit;
    border: 0;
    color: #858597;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
}
