@font-face {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
}
