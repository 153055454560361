.p-dialog {
  border-radius: $borderRadius;
  box-shadow: $overlayContainerShadow;
  border: $overlayContentBorder;

  .p-dialog-header {
    border-bottom: $dialogHeaderBorder;
    background: $dialogHeaderBg;
    color: $dialogHeaderTextColor;
    padding: $dialogHeaderPadding;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-family: 'PoppinsRegular';
    color: #000000;
    font-size: 14px;
    letter-spacing: -0.2px;
    font-weight: 400;

    .p-dialog-title {
      font-weight: $dialogHeaderFontWeight;
      font-size: $dialogHeaderFontSize;
    }

    .p-dialog-header-icon {
      @include action-icon();
      margin-right: $inlineSpacing;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .p-dialog-content {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    padding: $dialogContentPadding;
  }

  .p-dialog-footer {
    border-top: $dialogFooterBorder;
    background: $overlayContentBg;
    color: $panelFooterTextColor;
    padding: $dialogFooterPadding;
    text-align: right;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    button {
      margin: 0 $inlineSpacing 0 0;
      width: auto;
      background: #3d5cff;
    }
  }

  &.p-confirm-dialog {
    .p-confirm-dialog-message {
      font-family: 'PoppinsRegular';
      color: #000000;
      font-size: 14px;
      letter-spacing: -0.2px;
      font-weight: 400;
    }

    .p-confirm-dialog-reject {
      border: 1px solid #3d5cff;
      border-radius: 8px;
      background: none;
      color: #3d5cff;

      &:enabled:hover {
        color: white;
        background: #3d5cff;
      }
    }

    .p-confirm-dialog-accept {
      border-radius: 8px;
      background: #3d5cff;
      color: #f3f3f3;

      &:enabled:hover {
        opacity: 0.8;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .p-confirm-dialog-icon {
      font-size: $primeIconFontSize * 2;
    }

    .p-confirm-dialog-message {
      margin-left: $inlineSpacing * 2;
    }
  }
}

.p-dialog-mask.p-component-overlay {
  background-color: $maskBg;
}
