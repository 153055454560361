.questionnaire-container {
  &-body {
    margin: 1rem;
  }
}

.question-label {
  font-family: PoppinsSemiBold, sans-serif;
  font-size: 16px;
}

.card.answer-option.selected {
  background-color: #ff00f7;

  .answer-option-label {
    color: white;
  }
}

.payment-option-container {
  .card {
    margin: 0 0 1rem;
  }

  &-top {
    // .container {
    display: grid;
    gap: 2px;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-template-areas:
      'product-logo .'
      'product-logo .'
      'product-logo .';
    grid-template-columns: 0.2fr 1.8fr;
    grid-template-rows: 1fr 1fr 1fr;
    // }

    .product-logo {
      align-self: center;
      grid-area: product-logo;
      height: 48px;
      width: 48px;
    }
  }

  button {
    background: #3d5cff;
    border: 1px solid #3d5cff;
  }
}

.answer-option {
  border-radius: 10px;
  cursor: pointer;
  margin: auto auto 16px;
  width: 95%;

  &-label {
    align-self: center;
    // Fok. Eina
    font-family: PoppinsRegular, sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left !important;
  }
}

.question-count-label {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
}

.button-container {
  display: flex;
}

.question-btn {
  background-color: #3d5cff;
  border-radius: 8px;
  color: #fff;
  font-family: PoppinsRegular, sans-serif;
  height: 50px;
  margin: auto 10px;
  width: 100%;

  &.none {
    display: none;
  }

  .p-button-icon {
    font-size: 1.25rem !important;
  }

  span {
    font-size: 15px;
  }
}
