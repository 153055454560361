@media screen and (min-width: $tabletBreakpoint + 1) {
  .layout-content-wrapper {
    margin-left: $sidebarWidth;
  }

  .card {
    box-shadow: 0px 8px 12px -4px rgba(184, 184, 210, 0.2);
    font-family: 'PoppinsMedium';
  }

  .card.no-gutter.orders {
    height: 100%;
  }

  .p-col-12 {
    h5 {
      width: fit-content;
      float: left;
      font-size: 25px;
      font-family: 'PoppinsRegular';
      font-weight: normal;
      color: black;
    }
  }

  // button.p-component {
  //     background: #3d5cff;
  //     border-color: #3d5cff;
  // }

  .external-header {
    width: 100%;
    display: inline-block;
    height: auto;
    margin-bottom: 6px;
    margin-top: 28px;

    h5 {
      width: fit-content;
      float: left;
      padding-top: 8px;
      padding-bottom: 14px;
      font-size: 25px;
      font-family: 'PoppinsRegular';
      font-weight: normal;
    }

    .p-button-icon-only {
      background: none;
      border: none;
      color: black;
    }

    .p-button-icon-only:enabled:hover {
      background: lightgrey;
      border: none;
      color: black;
    }

    .p-input-icon-left {
      float: right;
      position: relative;

      .p-inputtext {
        border-radius: 10px;
      }
    }

    .p-fileupload-choose {
      padding: 7px;
      background: none;
      color: black;
      border: 0px;
      margin-top: 3.5px;
      .p-button-icon-left {
        margin-right: 0px;
      }
    }

    .p-fileupload-choose:hover {
      background: lightgrey;
      color: black;
    }

    .hotspots-top-download-dropdown {
      height: 35px;
      margin-right: 10px;
    }

    .floating-right-container {
      float: right;

      .p-input-icon-left {
        margin-left: 10px;

        .p-inputtext {
          border-radius: 10px;
        }
      }
    }
  }

  .merchant-tabs,
  .transaction-tabs {
    width: 100%;
    display: inline-block;
    height: auto;

    .p-button-icon-only {
      background: none;
      border: none;
      color: black;
    }

    .p-button-icon-only:enabled:hover {
      background: lightgrey;
      border: none;
      color: black;
    }

    h5 {
      width: fit-content;
      float: left;
      font-size: 20px;
      font-family: 'PoppinsRegular';
      font-weight: normal;
    }

    .p-button-secondary {
      background: none;
      border: none;
      color: black;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
      box-shadow: none;
    }

    .p-tabview-panels {
      padding: 0px;
    }

    .p-tabview.p-tabview-nav {
      border-width: 0px;
      li {
        .p-tabview-nav-link {
          a {
            background: none;
          }
        }
      }
    }

    .floating-right-container {
      float: right;

      .p-button-secondary {
        margin-bottom: 0px;
      }

      .p-input-icon-left {
        margin-left: 10px;

        .p-inputtext {
          border-radius: 10px;
        }
      }
    }

    .p-tabview.p-component {
      display: inline-block;
    }

    div {
      ul {
        background: none;
        border: none;

        a {
          background: $transactiontabsBackgroundColor;
          display: block;
          height: 130%;
          border: none;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          span {
            font-size: 18px;
            vertical-align: top;
          }
        }

        .p-highlight a {
          background: $transactiontabsActiveBackgroundColor;
          display: block;
          box-shadow: none;
          span {
            top: 14px;
            font-weight: bold;
            font-size: 18px;
            vertical-align: top;
            color: #f72fdc;
          }
        }

        li {
          a {
            background: $transactiontabsBackgroundColor;
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .datatable-card-wrapper {
    .card {
      box-shadow: none;
      border-radius: 10px;

      .p-datatable.p-component.datatable-responsive {
        border: 1px solid #e9ecef;
        border-radius: 10px;
      }

      .p-paginator-bottom {
        border: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .datatable-responsive {
        .p-datatable-header {
          border-top-width: 0px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        .firstColumn { /* stylelint-disable-line */
          border-top-left-radius: 10px;
        }

        .lastColumn { /* stylelint-disable-line */
          border-top-right-radius: 10px;
        }
      }
    }

    th {
      span {
        font-weight: bold;
      }
      span.p-column-title {
        font-family: 'PoppinsRegular';
        color: black;
      }
    }

    td {
      color: black;
      font-family: 'PoppinsRegular';
      .p-column-title {
        display: none;
      }
    }
  }

  .layout-content-wrapper-admin-retailer {
    position: absolute;
    width: 100%;
    min-height: 100%;
    margin-left: 0px;
    padding-bottom: 3rem;
  }

  .layout-wrapper {
    &.layout-static {
      .layout-content-wrapper {
        margin-left: $sidebarWidth;
      }

      .merchant-tabs,
      .transaction-tabs {
        width: 100%;
        display: inline-block;
        height: auto;
        h5 {
          width: fit-content;
          float: left;
        }

        .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus {
          box-shadow: none;
        }

        .p-tabview-panels {
          height: 0px;
          //visibility: hidden;
          padding: 0px;
        }

        .p-tabview {
          .p-tabview-nav {
            border-width: 0px;
            background: none;
            border: none;

            .p-unselectable-text {
              .p-tabview-nav-link {
                background: none;
              }
            }

            .p-tabview-nav-link {
              background: $transactiontabsBackgroundColor;
              display: block;
              height: 130%;
              border: none;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
              .p-tabview-title {
                font-family: 'PoppinsRegular';
                font-size: 18px;
                vertical-align: top;
                position: relative;
                top: -2px;
                left: 0px;
              }
            }

            .p-highlight .p-tabview-nav-link {
              background: $transactiontabsActiveBackgroundColor;
              display: block;
              box-shadow: none;
              .p-tabview-title {
                font-family: 'PoppinsRegular';
                font-weight: bold;
                font-size: 18px;
                vertical-align: top;
                color: #f72fdc;
                position: relative;
                top: -2px;
                left: 0px;
              }
            }

            li {
              a {
                background: $transactiontabsBackgroundColor;
                span {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .layout-content-wrapper-admin-retailer {
        position: absolute;
        width: 100%;
        min-height: 100%;
        margin-left: 0px;
        padding-bottom: 3rem;

        .layout-topbar-menu {
          z-index: 1000;

          .mobile-logout {
            display: none;
          }
        }

        .layout-topbar-menu-active {
          transform: translateX(0%);
          z-index: 999;
        }

        .menu-burger {
          display: none;
        }
      }
    }

    &.layout-static-inactive {
      .layout-topbar {
        .menu-button {
          i:before {
            content: '\e901';
          }
        }
      }

      .layout-sidebar {
        transform: translateX(-100%);
      }

      .layout-topbar-menu {
        transform: translateX(-100%);
      }

      .layout-topbar-menu-active {
        transform: translateX(-100%);
      }

      .layout-content-wrapper {
        margin-left: 0;
      }

      .layout-content-wrapper-admin-retailer {
        margin-left: 0;
      }
    }

    &.layout-overlay {
      .layout-topbar {
        .menu-button {
          i:before {
            content: '\e901';
          }
        }
      }

      .layout-content-wrapper {
        margin-left: 0;
      }

      .layout-content-wrapper-admin-retailer {
        margin-left: 0;
      }

      .layout-sidebar {
        z-index: 999;
        transform: translateX(-100%);
        box-shadow: none;
      }

      .layout-topbar-menu {
        z-index: 999;
        transform: translateX(-100%);
        box-shadow: none;
      }

      .layout-topbar-menu-active {
        z-index: 999;
        transform: translateX(-100%);
        box-shadow: none;
      }

      &.layout-overlay-active {
        .layout-sidebar {
          transform: translateX(0);
          box-shadow: $overlayShadow;
        }
      }
    }
  }
}

@media screen and (max-width: $tabletBreakpoint) {
  .layout-content {
    padding-top: 6rem;
  }

  .blocked-scroll {
    overflow: hidden;
  }

  .layout-wrapper {
    .layout-topbar.desktop-logout {
      display: none;
    }

    .layout-topbar {
      height: 7.125rem;
      @include flex-wrap(wrap);
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 998;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

      .topbar-left {
        width: 100%;
        padding: 0.5rem 0;
        @include flex-justify-between();
        border-bottom: 1px solid $dividerColor;

        .topbar-separator {
          display: none;
        }
      }

      .topbar-right {
        width: 100%;
        padding: 0.5rem 0;
      }

      .menu-button {
        i:before {
          content: '\e91d';
        }
      }

      .mobile-logo {
        display: block;
        width: 2rem;
        height: 2rem;
      }

      .viewname {
        font-size: 1rem;
      }

      .topbar-menu {
        @include flex-justify-between();

        > li {
          margin-left: 0;

          &.profile-item {
            border-left: 0;
            border-right: 0;
            padding: 0;

            > a,
            > .p-link {
              padding: 0;
            }

            .profile-image {
              width: 2rem;
              height: 2rem;
              margin: 0;
            }

            .profile-name {
              display: none;
            }
          }

          > ul {
            top: 2.75rem;
          }
        }
      }
    }

    .layout-content-wrapper {
      margin-left: 0;
      padding-top: 7.125rem;
    }

    .layout-content-wrapper-admin-retailer {
      position: absolute;
      width: 100%;
      min-height: 100%;
      margin-left: 0;
      padding-top: 0rem;
      padding-bottom: 3rem;

      .menu-burger {
        top: 10px;
        left: 2rem;
        background: none;
        color: grey;
        border: none;
        box-shadow: none;
        width: 60px;
        height: 60px;
        position: fixed;
        z-index: 2000;

        .pi-bars {
          font-size: 41px;
        }
      }
    }

    .layout-sidebar {
      z-index: 999;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
      transform: translateX(-100%);
    }

    .layout-topbar-menu {
      z-index: 999;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
      transform: translateX(-100%);
      display: block;
    }

    .layout-topbar-menu-active {
      z-index: 999;
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.15);
      transform: translateX(0%);
      display: flex;
      flex-flow: column;
      height: 100%;

      .mobile-logout {
        margin-top: auto;
        position: absolute;
        left: 39px;
        bottom: 1rem;
        font-family: 'PoppinsMedium';
        font-size: 16px;
      }
    }

    &.layout-mobile-active {
      .layout-sidebar {
        transform: translateX(0);
      }

      .layout-topbar-menu {
        transform: translateX(0);
      }

      .layout-mask {
        display: block;
      }
    }

    .layout-search {
      .search-container {
        width: 75vw;
      }
    }
  }
}

@media screen and (max-width: $phoneBreakpoint) {
  .layout-wrapper {
    .layout-topbar {
      .topbar-menu {
        > li {
          position: static;

          > ul {
            position: fixed;
            top: 7.125rem;
            left: 0;
            width: 100%;
            right: auto;
          }
        }
      }
    }
  }
}
