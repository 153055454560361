$primaryColor: #1e98fd;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;

$highlightBg: #e3f2fd;
$highlightTextColor: #495057;

@import "../../sass/theme/_theme_light";
